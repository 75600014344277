import { useSelector } from "react-redux";
import { translateComponent } from "../../utils/services/translate";
// utils
import moment from "moment";

//scss
export function ValidationInformationDialog({ ticket, isOpen, onClose }) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);

  if (!isOpen) return null;
  if (ticket.maxAllowedAttempts === 100) {
    return (
      <div className="information-dialog bg-white text-dark text-start h-100">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4">
                <p className="">
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "unlimitedTicketText1"
                  )}
                </p>
                <p>
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "unlimitedTicketText2"
                  )}{" "}
                  <b>
                    {" "}
                    {moment(ticket.expirationDate).format("YYYY-MM-DD HH:mm")}.
                  </b>
                </p>
              </div>
              <div className="col-12 pb-3 pe-2 ps-2 pt-5 mt-2"></div>
              <div className="col-12 pb-3 pe-2 ps-2 pt-5 mt-5">
                <button className="btn btn-secondary w-100" onClick={onClose}>
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "closeButtonText"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="information-dialog bg-white text-dark text-start h-100">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4">
                <p className="">
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "ticketCounterText1"
                  )}{" "}
                  {ticket.maxAllowedAttempts}{" "}
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "ticketCounterText2"
                  )}{" "}
                  &nbsp;
                  <b>{ticket.validationAttempts.length} </b>
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "ticketCounterText3"
                  )}{" "}
                  <b>{ticket.maxAllowedAttempts}</b>{" "}
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "ticketCounterText4"
                  )}{" "}
                </p>
                <p>
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "ticketCounterText5"
                  )}{" "}
                  <b>
                    {moment(ticket.expirationDate).format("YYYY-MM-DD HH:mm")}
                  </b>
                </p>
              </div>
              <div className="col-12 pb-3 pe-2 ps-2 pt-5 mt-5">
                <button className="btn btn-secondary w-100" onClick={onClose}>
                  {translateComponent(
                    translations,
                    language,
                    "validationInformationDialog",
                    "closeButtonText"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// dateFormatter.js
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-gb";
import "moment/locale/fi";

const formatDateWithDay = (date, locale) => {
  const formattedDate = moment
    .utc(date, moment.ISO_8601)
    .locale(locale)
    .format("dddd, YYYY-MM-DD HH:mm");
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export default formatDateWithDay;

// @flow
import { LayoutActionTypes } from "./constants";

const INIT_STATE = {
  loading: false,
  loading_message_sv: null,
  loading_message_en: null,
  show_navigation: false,
  show_error_message: false,
  error_message: null,
  show_languague_menu: false,
  disable_navigation: false,
  language: "en-US",
  languageOptions: ["en-US", "sv-SE", "fi-FI"],
  translations: {},
};

type LayoutAction = { type: string, payload?: string | null };

type State = {};

const Layout = (state: State = INIT_STATE, action: LayoutAction): any => {
  switch (action.type) {
    case LayoutActionTypes.SET_LOADING_MESSAGE:
      if (action.payload.en !== undefined && action.payload.sv !== undefined) {
        return {
          ...state,
          loading_message_sv: action.payload.sv,
          loading_message_en: action.payload.en,
          loading: true,
        };
      } else {
        return {
          ...state,
          loading_message_sv: "Laddar..",
          loading_message_en: "Loading..",
          loading: true,
        };
      }

    case LayoutActionTypes.REMOVE_LOADING_MESSAGE:
      return {
        ...state,
        loading_message_sv: null,
        loading_message_en: null,
        loading: false,
      };
    case LayoutActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LayoutActionTypes.SHOW_NAVIGATION_MENU:
      return {
        ...state,
        show_navigation: action.payload,
      };
    case LayoutActionTypes.DISABLE_NAVIGATION:
      return {
        ...state,
        disable_navigation: action.payload,
      };
    case LayoutActionTypes.SET_SHOW_ERROR:
      if (action.payload === false) {
        return {
          ...state,
          show_error_message: action.payload,
          error_message: null,
        };
      }
      return {
        ...state,
        show_error_message: action.payload,
      };
    case LayoutActionTypes.SET_SHOW_ERROR_MESSAGE:
      if (action.payload !== null) {
        return {
          ...state,
          error_message: action.payload,
          show_error_message: true,
        };
      } else {
        return {
          ...state,
          error_message: action.payload,
          show_error_message: false,
        };
      }

    case LayoutActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case LayoutActionTypes.SET_LANGUAGE_OPTIONS:
      return { ...state, languageOptions: action.payload };
    case LayoutActionTypes.SET_TRANSLATIONS:
      return { ...state, translations: action.payload };
    case LayoutActionTypes.SET_SHOW_LANGUAGE_MENU:
      return { ...state, show_languague_menu: action.payload };
    default:
      return state;
  }
};

export default Layout;

import React from "react";

// css
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer mt-5 text-center d-print-none">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="m-0 text-m">Nobina Sverige AB</p>
            <p className="m-0 text-m">Org nr: 556057-0128</p>
            <p className="m-0 text-m">hyrbuss@nobina.se</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="m-0 text-m">Postaddress</p>
            <p className="m-0 text-m">Armégatan 38</p>
            <p className="m-0 text-m">171 06 SOLNA</p>
          </div>
          <div className="col-12 text-center">
            <p className="text-extra-bold m-0 text-m">COPYRIGHT Nobina 2023</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// @flow
import { all } from 'redux-saga/effects';



function* PaymentSaga(): any {
    yield all([
    ]);
}


export default PaymentSaga;

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postPasscode } from "../utils/api/protected/protectedClientHelper";
import {
  resetLoginStates,
  setVerificationCodeNotAccepted,
  setShowNavigationMenu,
  setLoading,
  setDisableNavigation,
} from "../redux/actions";
const authContext = React.createContext();

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.Login);
  const phoneNumber = login.phone_number;
  const verificationCode = login.verification_code;
  return {
    login(event) {
      event.preventDefault();
      dispatch(setVerificationCodeNotAccepted(false));
      dispatch(setLoading(true));
      postPasscode(phoneNumber, verificationCode).then((res) => {
        dispatch(setLoading(false));

        if (res.token) {
          return new Promise((result) => {
            dispatch(resetLoginStates());
            dispatch(setShowNavigationMenu(true));
            dispatch(setDisableNavigation(false));
          });
        } else {
          dispatch(setVerificationCodeNotAccepted(true));
        }
      });
    },
    loginRedirect(event, url) {
      event.preventDefault();
      dispatch(setVerificationCodeNotAccepted(false));
      dispatch(setLoading(true));
      postPasscode(phoneNumber, verificationCode).then((res) => {
        dispatch(setLoading(false));
        if (res.token) {
          return new Promise((result) => {
            navigate(url);
          });
        } else {
          dispatch(setVerificationCodeNotAccepted(true));
        }
      });
    },
    logout() {
      return new Promise((res) => {
        localStorage.removeItem("tokenExpireAt");
        localStorage.removeItem("refreshTokenExpireAt");
        localStorage.removeItem("phoneNumber");
        window.location.href = "/";
        res();
      });
    },
    isAuthed() {
      var phone = localStorage.getItem("phoneNumber");
      if (phone === undefined || phone === null) {
        return false;
      }
      return true;
    },
    phoneNumber() {
      let phone = localStorage.getItem("phoneNumber");
      if (phone === undefined || phone === null) {
        return "";
      }
      return phone;
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// redux
import { setLoading, setLoadingMessage } from "../../../../redux/actions";

// api
import {
  getTicketRefundStatus,
  PostTicketRefund,
} from "../../../../utils/api/restClient";

// helpers
import { translatePage } from "../../../../utils/services/translate";

// components
import { LoadingComponent } from "../../../../components/layout/loading/LoadingComponent";

// scss
import "./ticketRefundPage.scss";

function TicketRefundPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.Layout.language);
  const loading = useSelector((state) => state.Layout.loading);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const { referenceId, ticketId } = useParams();
  const [refundable, setRefundable] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [refundResult, setRefundResult] = useState(null);
  const [error, setError] = useState(null);

  const postTicketRefundFromApi = async (
    orderReferenceId,
    ticketReferenceId
  ) => {
    try {
      dispatch(
        dispatch(
          setLoadingMessage("Startar återbetalninng..", "Starting refunding...")
        )
      );
      const res = await PostTicketRefund(orderReferenceId, ticketReferenceId);
      if (res.refundStarted) {
        setRefundResult(true);
      } else {
        setRefundResult(false);
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Error fetching ticket:", error);
      dispatch(setLoading(false));
      setError(true);
    }
  };

  function getTicketName(lang, ticket) {
    let svSe = ticket.translations.find(
      (translation) => translation.locale === "sv-SE"
    );
    let enUs = ticket.translations.find(
      (translation) => translation.locale === "en-US"
    );
    if (lang === "sv") {
      return svSe.name + " " + svSe.description;
    } else {
      return enUs.name + " " + enUs.description;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);

    const getTicketRefundStatusFromApi = async () => {
      try {
        dispatch(
          dispatch(
            setLoadingMessage(
              "Hämtar biljett status.",
              "Getting ticket status."
            )
          )
        );
        const res = await getTicketRefundStatus(ticketId);
        if (res.refundable === true) {
          setRefundable(true);
          setTicket(res.ticket);
        } else {
          setRefundable(false);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        setError(true);
      }
    };

    getTicketRefundStatusFromApi(ticketId);
  }, [ticketId, dispatch]);
  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }
  if (error === true) {
    return (
      <div class="container d-flex justify-content-center min-vh-100 mt-5 pt-5 ">
        <div className="row">
          <div className="col-12">
            <div className="bg-danger p-3">
              <h1 className="text-white">
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetailsh1"
                )}{" "}
              </h1>
              <p className="text-white">
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetails1"
                )}{" "}
              </p>
              <p className="text-white">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetails2"
                )}
              </p>
            </div>
            <button
              className="btn btn-secondary w-100 mt-4"
              onClick={() => navigate("/home/orders/" + referenceId)}
            >
              {" "}
              {translatePage(
                translations,
                language,
                "ticketRefundPage",
                "goBackButtonText"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (refundResult) {
    return (
      <div class="container d-flex justify-content-center min-vh-100 mt-5 pt-5 ">
        <div className="ticketRefundContainer">
          <div className="row">
            <div className="col-12">
              <div className="bg-light p-3">
                <h1 className="text-center">
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "refundSuccessH1Text"
                  )}
                </h1>
                <p>
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "refundSuccessText"
                  )}
                </p>
              </div>
              <button
                className="btn btn-secondary w-100 mt-4"
                onClick={() => navigate("/home/orders/" + referenceId)}
              >
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "goBackButtonText"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (refundResult === false) {
    return (
      <div class="container d-flex justify-content-center min-vh-100 mt-5 pt-5 ">
        <div className="row">
          <div className="col-12">
            <div className="bg-danger p-3">
              <h1 className="text-white">
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetailsh1"
                )}{" "}
              </h1>
              <p className="text-white">
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetails1"
                )}{" "}
              </p>
              <p className="text-white">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "techErrorDetails2"
                )}
              </p>
            </div>
            <button
              className="btn btn-secondary w-100 mt-4"
              onClick={() => navigate("/home/orders/" + referenceId)}
            >
              {" "}
              {translatePage(
                translations,
                language,
                "ticketRefundPage",
                "goBackButtonText"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (refundable && refundResult === null) {
    return (
      <div class="container d-flex justify-content-center min-vh-100 mt-5 pt-5 ">
        <div className="row">
          <div className="col-12">
            <div className="bg-light p-3">
              <h1>
                {" "}
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "refundH1Title"
                )}
              </h1>
            </div>

            <div className="p-2">
              <p>
                <strong>
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "ticket"
                  )}
                  :
                </strong>{" "}
                {getTicketName(lang, ticket)}
              </p>

              <p>
                <strong>
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "totalAmount"
                  )}
                  :
                </strong>{" "}
                {ticket.price}
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "currency"
                )}
              </p>
              <div className="bg-info2 p-3 text-dark">
                <p>
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "paymenValidForRefundMessage"
                  )}
                </p>
                <p>
                  {translatePage(
                    translations,
                    language,
                    "ticketRefundPage",
                    "paymenValidForRefundMessage2"
                  )}
                </p>
              </div>
            </div>

            <div className="col-12 pt-3">
              <button
                className="btn btn-success w-100"
                onClick={() => postTicketRefundFromApi(referenceId, ticketId)}
              >
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "refundButtonText"
                )}
              </button>
            </div>
            <div className="col-12 pt-3">
              <button
                className="btn btn-secondary w-100"
                onClick={() => navigate("/home/orders/" + referenceId)}
              >
                {translatePage(
                  translations,
                  language,
                  "ticketRefundPage",
                  "goBackButtonText"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <p className="mt-5 pt-5">
      {translatePage(
        translations,
        language,
        "ticketRefundPage",
        "couldNotFindTicketErrorText"
      )}{" "}
      {referenceId}
    </p>
  );
}

export default TicketRefundPage;

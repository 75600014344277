export function getCurrencyLabel(language, currency) {
  if (language === "en-US" && currency === "SEK") {
    return "SEK";
  }
  if (language === "en-US" && currency === "EUR") {
    return "EUR";
  }
  if (language === "sv-SE" && currency === "EUR") {
    return "EUR";
  }
  if (language === "sv-SE" && currency === "SEK") {
    return "kr";
  }
  if (language === "fi-FI" && currency === "SEK") {
    return "SEK";
  }
  if (language === "fi-FI" && currency === "EUR") {
    return "EUR";
  }

  if (currency === null || currency === undefined) {
    if (language === "sv-SE") {
      return "kr";
    } else {
      return "SEK";
    }
  }
}

export default getCurrencyLabel;

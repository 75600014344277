import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

// compontes
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";
// lang
import { translatePage } from "../../utils/services/translate";

// redux
import {
  setLoading,
  setLoadingMessage,
  setLayoutShowErrorMessage,
  setSelectedOrder,
  setTicketList,
} from "../../redux/actions";

// api
import { getProtectedOrder } from "../../utils/api/restClient";
import TicketListCarusell from "../../components/ticket/ticketListCarusell";

// clear tokens
import { removeExpiredTokens } from "../../components/ticket/ticketLocalStorageHelper";
// scss
import "./receiptPage.scss";

const ReceiptPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.Order.selected_order);
  const tickets = useSelector((state) => state.Ticket.ticket_list);
  const language = useSelector((state) => state.Layout.language);
  const loading = useSelector((state) => state.Layout.loading);

  const translations = useSelector((state) => state.Layout.translations);
  const { referenceId } = useParams();
  const [orderNotFound, setOrderNotFound] = useState(false);

  const [retryCount, setRetryCount] = useState(0);
  const [nextRetryIn, setNextRetryIn] = useState(0);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    removeExpiredTokens();
    const findOrder = async () => {
      try {
        dispatch(setLoadingMessage("Rullar fram..", "Getting there.."));
        const res = await getProtectedOrder(referenceId);
        if (
          res === undefined ||
          res.phoneNumber === undefined ||
          res.status === 400
        ) {
          setOrderNotFound(true);
        } else {
          dispatch(setSelectedOrder(res));
          if (res.tickets !== null) {
            dispatch(setTicketList(res.tickets));
          } else {
            if (res.status === "paid") {
              const receiptUrl = "/order/" + res.referenceId;
              navigate(receiptUrl);
            }
          }
          return res;
        }
      } catch (error) {
        setOrderNotFound(true);
        dispatch(
          setLayoutShowErrorMessage(
            "Technical error",
            "Could not find your order."
          )
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    let retryTimeout;
    let countdownInterval;

    const checkOrderStatus = async () => {
      if (retryCount >= 4) {
        setNextRetryIn(0);
        return;
      }

      try {
        const response = await findOrder();
        if (response && response.status === "pendingPayment") {
          setNextRetryIn(3);

          countdownInterval = setInterval(() => {
            setNextRetryIn((prev) => (prev > 0 ? prev - 1 : 0));
          }, 3000);

          retryTimeout = setTimeout(() => {
            setRetryCount((prevCount) => {
              if (prevCount < 3) {
                checkOrderStatus();
                return prevCount + 1;
              }
              return prevCount;
            });
          }, 3000);
        } else {
          setRetryCount(0);
          setNextRetryIn(0);
          clearInterval(countdownInterval);
        }
      } catch (error) {
        console.error("Error fetching order:", error);
        clearInterval(countdownInterval);
      }
    };

    checkOrderStatus();

    return () => {
      if (retryTimeout) clearTimeout(retryTimeout);
      if (countdownInterval) clearInterval(countdownInterval);
    };
  }, [referenceId, navigate, dispatch, retryCount]);
  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }
  if (orderNotFound) {
    return (
      <div className="container min-height-100 mt-5 pt-5">
        <div className="row">
          <div className="col-12 bg-light p-3">
            <h1 className="">
              {translatePage(
                translations,
                language,
                "receiptPage",
                "somethingWentWrongTitle"
              )}
            </h1>
            <p className="text-m">
              {translatePage(
                translations,
                language,
                "receiptPage",
                "somethingWentWrongDescription"
              )}
            </p>
            <div className="row">
              <div className="col-12 text-center p-3 pb-3">
                <button
                  className="btn btn-secondary text-m"
                  onClick={() => window.location.reload()}
                >
                  {translatePage(
                    translations,
                    language,
                    "receiptPage",
                    "refreshPageButton"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5 text-center text-m">
            <a
              href={translatePage(
                translations,
                language,
                "receiptPage",
                "mailContent"
              )}
            >
              <button className="btn btn-success pr-5 mr-5 text-white text-m">
                {translatePage(
                  translations,
                  language,
                  "receiptPage",
                  "sendEmailButton"
                )}
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (order !== null) {
    if (order.status === "abandoned") {
      <div className="container min-height-100">
        <div className="row">
          <h1 className="mt-5 pt-5 text-center ">
            {translatePage(
              translations,
              language,
              "receiptPage",
              "somethingWentWrongTitle"
            )}
          </h1>
          <p className="text-center">
            {" "}
            {translatePage(
              translations,
              language,
              "receiptPage",
              "orderNotPayedMessage"
            )}
          </p>
        </div>
      </div>;
    }
    if (order.status === "pendingPayment") {
      return (
        <div className="center-vertically ">
          <div class="paymentPendingContainer ">
            {retryCount < 4 && nextRetryIn > 0 && (
              <div className="bg-light p-3">
                <h3 className="text-center pb-3">
                  {" "}
                  {translatePage(
                    translations,
                    language,
                    "receiptPage",
                    "waitingForPaymentH3"
                  )}
                </h3>
                <HashLoader
                  cssOverride={override}
                  color={"#39a935"}
                  loading={true}
                />
                <p className="pb-3"> </p>
              </div>
            )}
            <ul class="list-group list-group-flush">
              <li class="list-group-item bg-light">
                <strong>Id:</strong> {order.referenceId}
              </li>
            </ul>
          </div>
          {retryCount === 3 && (
            <div className="row paymentPendingContainer">
              <div className="col-12 p-4 text-center">
                {translatePage(
                  translations,
                  language,
                  "receiptPage",
                  "waitingForPaymentMessage"
                )}
              </div>
              <div className="col-12 text-center">
                <div>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {translatePage(
                      translations,
                      language,
                      "receiptPage",
                      "tryAgainButtonText"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    if (order.status === "paid") {
      if (tickets !== null && tickets.length > 0) {
        return (
          <>
            <div className="container min-height-100 mt-5">
              <div className="row pt-4">
                <div className="col-12 col-centered">
                  <TicketListCarusell />
                </div>

                <div className="col-12 col-centered mt-2">
                  <span className=" d-inline">
                    <Link
                      className="text-extra-bold"
                      to={"/order/" + order.referenceId}
                    >
                      {translatePage(
                        translations,
                        language,
                        "receiptPage",
                        "receipt"
                      )}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="container min-height-100  d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-12 col-centered ">
                <span className=" d-inline">
                  {translatePage(
                    translations,
                    language,
                    "receiptPage",
                    "myPage"
                  )}
                  <Link
                    id={"page_landingPage_myPages"}
                    className="text-extra-bold"
                    to={"/order/" + order.referenceId}
                  >
                    {translatePage(
                      translations,
                      language,
                      "receiptPage",
                      "myPage"
                    )}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  }
};

export default ReceiptPage;

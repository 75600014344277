// @flow
import { combineReducers } from "redux";

import Layout from "./layout/reducers";
import Payment from "./payment/reducers";
import Product from "./product/reducers";
import Order from "./order/reducers";
import Ticket from "./ticket/reducers";
import Login from "./login/reducers";
import Category from "./category/reducers";
import Project from "./project/reducers";
export default (combineReducers({
  Layout,
  Payment,
  Product,
  Ticket,
  Order,
  Login,
  Category,
  Project,
}): any);

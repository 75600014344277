// @flow
import { TicketActionTypes } from "./constants";

const INIT_STATE = {
  selected_ticket: null,
  ticket_not_found: false,
  ticket_list: null,
  ticket_list_selected_id: null,
};

type TicketAction = { type: string, payload?: string | null };

type State = {};

const Order = (state: State = INIT_STATE, action: TicketAction): any => {
  switch (action.type) {
    case TicketActionTypes.SET_SELECTED_TICKET:
      return {
        ...state,
        selected_ticket: action.payload,
      };
    case TicketActionTypes.SET_TICKET_NOT_FOUND:
      return {
        ...state,
        ticket_not_found: action.payload,
      };
    case TicketActionTypes.SET_TICKET_LIST:
      return {
        ...state,
        ticket_list: action.payload,
      };
    case TicketActionTypes.SET_TICKET_LIST_SELECTED_ID:
      return {
        ...state,
        ticket_list_selected_id: action.payload,
      };
    case TicketActionTypes.UPDATE_TICKET:
      return {
        ...state,
        ticket_list: state.ticket_list.map((ticket) =>
          ticket.referenceId === action.payload.referenceId
            ? action.payload
            : ticket
        ),
      };
    default:
      return state;
  }
};

export default Order;

import React, { useState, useEffect, useCallback } from "react";

function SwishCountDownTimerComponent({ serverTime, endTime }) {
  const calculateTimeLeft = useCallback(
    (currentTime) => {
      const end = new Date(endTime);
      const difference = end - currentTime;
      return difference > 0
        ? {
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          }
        : {};
    },
    [endTime]
  );

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(new Date(serverTime))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const newTimeLeft = calculateTimeLeft(currentTime);

      if (Object.keys(newTimeLeft).length === 0) {
        clearInterval(interval);
        window.location.href = window.location.href;
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeLeft]);

  const formatTime = (time) => String(time).padStart(2, "0");

  return (
    <div>
      {Object.keys(timeLeft).length > 0 ? (
        <p className="text-center bg-light">
          {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:
          {formatTime(timeLeft.seconds)}
        </p>
      ) : (
        <span></span>
      )}
    </div>
  );
}

export default SwishCountDownTimerComponent;

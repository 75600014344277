export const LoginActionTypes = {
  SET_SMS_SENT: "@@login/SET_SMS_SENT",
  SET_SMS_SENT_FAILURE: "@@login/SET_SMS_SENT_FAILURE",
  SET_PHONE_NUMBER: "@@login/SET_PHONE_NUMBER",
  SET_PHONE_CHAR: "@@login/SET_PHONE_CHAR",
  SET_PHONE_VALID: "@@login/SET_PHONE_VALID",
  SET_VERIFICATION_CODE_VALID: "SET_VERIFICATION_CODE_VALID",
  SET_VERIFICATION_CODE: "@@login/SET_VERIFICATION_CODE",
  SET_VERIFICATION_CODE_LENGTH: "@@login/SET_VERIFICATION_CODE_LENGTH",
  SET_VERIFICATION_CODE_NOT_ACCEPTED:
    "@@login/SET_VERIFICATION_CODE_NOT_ACCEPTED",
  RESET_LOGIN_STATES: "@@RESET_LOGIN_STATES",
};

import React from "react";
import { connect } from "react-redux";

// components
import Navigation from "./Navigation";
import LanguageMenu from "./LanguageMenu";
import ShowEnvironmentComponent from "../custom/showEnvironmentComponent";
// css
import "./Topbar.scss";

// helpers
import { Logo } from "../../assets/img/NobinaLogoImg";

// actions
import {
  setShowNavigationMenu,
  setShowLanguageMenu,
} from "../../redux/actions";

function openNavigation(dispatch) {
  dispatch(setShowLanguageMenu(false));
  dispatch(setShowNavigationMenu(true));
}
function closeNavigation(dispatch) {
  dispatch(setShowNavigationMenu(false));
}

const toggleLanguage = (dispatch, props) => {
  if (props.show_languague_menu) {
    dispatch(setShowLanguageMenu(false));
  } else {
    dispatch(setShowLanguageMenu(true));
    dispatch(setShowNavigationMenu(false));
  }
};

const Topbar = (props): React$Element<any> => {
  return (
    <React.Fragment>
      <nav id={"navbar"} className="navbar navbar-light fixed-top d-flex">
        <Logo />

        {!props.disable_navigation ? (
          <>
            {!props.show_navigation ? (
              <>
                <ShowEnvironmentComponent></ShowEnvironmentComponent>

                <div className="menu-buttons" style={{ display: "flex" }}>
                  <button
                    className="btn btn-select-lang"
                    onClick={() => toggleLanguage(props.dispatch, props)}
                  ></button>
                  <button
                    className="menu-btn js-menu-button btn"
                    onClick={() => openNavigation(props.dispatch)}
                  >
                    <span className="menu-btn__line"></span>
                    <span className="menu-btn__line"></span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="menu-buttons" style={{ display: "flex" }}>
                  <button
                    className="btn btn-select-lang"
                    onClick={() => toggleLanguage(props.dispatch, props)}
                  ></button>
                  <button
                    className="menu-btn js-menu-button btn menu-btn--active"
                    onClick={() => closeNavigation(props.dispatch)}
                  >
                    <span className="menu-btn__line"></span>
                    <span className="menu-btn__line"></span>
                  </button>
                </div>
              </>
            )}
          </>
        ) : null}
      </nav>
      <Navigation />
      <LanguageMenu />
    </React.Fragment>
  );
};

function mapStateToProps({ Layout }) {
  return {
    Layout,
    show_navigation: Layout.show_navigation,
    disable_navigation: Layout.disable_navigation,
    show_languague_menu: Layout.show_languague_menu,
  };
}
export default connect(mapStateToProps)(Topbar);

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//components
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";
import SwishCheckoutSignalR from "./SwishCheckoutSignalR";
import SwishErrorComponent from "../../components/payment/swish/swishErrorComponent";
import SwishCountDownTimerComponent from "../../components/payment/swish/swishCountDownTimerComponent";

// redux
import { setLoading, setSwishPaymentResponseError } from "../../redux/actions";

// API
import {
  startSwishPayment,
  getProtectedOrder,
} from "../../utils/api/restClient";

// Translation
import { translatePage } from "../../utils/services/translate";

// scss
import "./swishCheckoutPage.scss";

function SwishCallbackPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const swishResponse = useSelector(
    (state) => state.Payment.swish_response_status
  );
  const loading = useSelector((state) => state.Layout.loading);
  const translations = useSelector((state) => state.Layout.translations);
  const language = useSelector((state) => state.Layout.language);

  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [orderNotFoundError, setOrderNotFoundError] = useState(false);

  const pendingStatusContainer =
    "container min-height-100 d-flex justify-content-center align-items-center";

  const startSwishPaymentRequest = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const res = await startSwishPayment(id);
      if (res.swishIntegrationResponse.tokenResponse) {
        dispatch(
          setSwishPaymentResponseError(
            id,
            res.swishIntegrationResponse.status.status,
            res.swishIntegrationResponse.status.errorMessage,
            res.swishIntegrationResponse.status.errorCode
          )
        );
      } else {
        setApiError(true);
      }
    } catch (error) {
      setApiError(true);
    } finally {
      dispatch(setLoading(false));
    }
  }, [id, dispatch]);

  const getOrderFromApi = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const res = await getProtectedOrder(id);
      return res;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }, [id, dispatch]);

  function isOlderThanFourMinutes(order) {
    if (!order || !order.createdDateTime) {
      return false;
    }

    const createdTime = new Date(order.createdDateTime);
    const currentTime = new Date();
    const fourMinutes = 4 * 60 * 1000; // 4 minutes
    return currentTime - createdTime > fourMinutes;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getOrderFromApi(id).then((res) => {
      if (res !== undefined && res.referenceId === id) {
        setOrder(res);
        startSwishPaymentRequest(id);
      } else {
        setOrderNotFoundError(true);
      }
    });
  }, [id]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (
    order !== null &&
    swishResponse !== null &&
    order.referenceId !== swishResponse.orderReference
  ) {
    return <LoadingComponent />;
  }

  if (
    order !== null &&
    swishResponse !== null &&
    order.referenceId === swishResponse.orderReference &&
    order.status === "paid"
  ) {
    navigate("/t/" + order.referenceId);
  }
  if (orderNotFoundError) {
    return (
      <div className="container pt-5 min-vh-100">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="mt-5 text-center">
              {" "}
              {translatePage(
                translations,
                language,
                "swishCheckoutPage",
                "errorOrderNotFoundH1"
              )}
            </h1>
            <p className="text-m">id: {id}</p>
          </div>
        </div>
      </div>
    );
  }
  if (apiError) {
    return (
      <div className="container min-height-100  d-flex justify-content-center align-items-center">
        <div className="swishErrorContainer">
          <div className="row">
            <div className="col-12">
              <h1 className="mt-5 ps-2 pe-2">
                {translatePage(
                  translations,
                  language,
                  "swishCheckoutPage",
                  "errorTechnicalh1"
                )}
              </h1>
              <p className="ps-2 pe-2">
                {translatePage(
                  translations,
                  language,
                  "swishCheckoutPage",
                  "errorTechnicalMessage"
                )}
              </p>
            </div>
          </div>
          <div className="col-12 text-center pt-4">
            <button
              className="btn btn-secondary w-100"
              onClick={() => navigate("/paymentcheck")}
            >
              {translatePage(
                translations,
                language,
                "swishCheckoutPage",
                "changePaymentMethodButton"
              )}
            </button>
          </div>
          <div className="col-12 text-center mt-2"></div>
        </div>
      </div>
    );
  }
  if (swishResponse === null) {
    return (
      <div className="container mt-5 pt-5 min-height-100 ">
        <div className="row mt-5">
          <div className="col-12"></div>
        </div>
      </div>
    );
  }
  if (swishResponse !== null && swishResponse.status === "PENDING") {
    if (isOlderThanFourMinutes(order)) {
      return (
        <>
          <div className={pendingStatusContainer}>
            <div className="swish-container">
              <div className="row">
                <div className="col-12 ">
                  <h1 className="text-danger text-center">
                    {translatePage(
                      translations,
                      language,
                      "swishCheckoutPage",
                      "errorOrderTimedOutH1"
                    )}
                  </h1>
                  <div className="border">
                    <p className="text-m p-3 mt-2 ms-2 pb-0">
                      {translatePage(
                        translations,
                        language,
                        "swishCheckoutPage",
                        "errorOrderTimedOutMessage1"
                      )}
                    </p>
                    <p className="text-m p-3 mt-2 ms-2 pb-0">
                      {translatePage(
                        translations,
                        language,
                        "swishCheckoutPage",
                        "errorOrderTimedOutMessage2"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      const swishPaymentStartTime = new Date(order.createdDateTime);
      const swishPaymentEndTime = new Date(
        swishPaymentStartTime.getTime() + 4 * 60000
      );
      const serverTime = new Date().toISOString(); // TODO: should me serverTime from API.

      return (
        <>
          <div className={pendingStatusContainer}>
            <div className="swish-container">
              <div className="row mt-5">
                <div className="col-12 ">
                  <div className="border">
                    {language === "sv" ? (
                      <p className="text-m p-3 mt-2 ms-2 pb-0 text-center">
                        Inväntar på svar från Swish.
                      </p>
                    ) : (
                      <p className="text-m p-3 mt-2 ms-2 pb-0 text-center">
                        Waiting for response from Swish.
                      </p>
                    )}

                    <div className="ps-2 pe-2 pb-3">
                      <div className="swish-icon-container">
                        <SwishCountDownTimerComponent
                          serverTime={serverTime}
                          endTime={swishPaymentEndTime.toISOString()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SwishCheckoutSignalR orderReference={id} />
        </>
      );
    }
  }

  if (swishResponse !== null && swishResponse.status !== "PENDING") {
    if (swishResponse.status === "PAID") {
      setTimeout(() => {
        if (order !== null) {
          navigate("/t/" + order.referenceId);
        }
      }, 3000);
      return (
        <div className="container min-height-100 d-flex justify-content-center align-items-center rounded">
          <div className="swish-error-container rounded bg-light">
            <div className="border p-3 rounded">
              <div className="row">
                <div className="col-12">
                  <h3>
                    {translatePage(
                      translations,
                      language,
                      "swishCheckoutPage",
                      "paymentSuccessH1"
                    )}
                  </h3>
                  <p>
                    {" "}
                    {translatePage(
                      translations,
                      language,
                      "swishCheckoutPage",
                      "paymentSuccessMessage"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container min-height-100 d-flex justify-content-center align-items-center rounded">
        <div className="swish-error-container rounded">
          <div className="border p-3 rounded">
            <SwishErrorComponent
              swishResponse={swishResponse}
              language={language}
              translations={translations}
            ></SwishErrorComponent>
            <div className="row">
              <div className="col-12">
                <button
                  onClick={() => navigate("/")}
                  className="btn btn-secondary w-100"
                >
                  {translatePage(
                    translations,
                    language,
                    "swishCheckoutPage",
                    "startPageButton"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <LoadingComponent />;
}

export default SwishCallbackPage;

import React from "react";
import { useSelector } from "react-redux";
import { translateComponent } from "../../utils/services/translate";
import "./taxComponent.scss";
function TaxComponent(props) {
  const order = useSelector((state) => state.Order.selected_order);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const sixPercentTax = order.items.filter(
    (item) => item.taxRate === 0.06 && item.status !== "refunded"
  );
  const tenPercentTax = order.items.filter(
    (item) => item.taxRate === 0.1 && item.status !== "refunded"
  );
  const twelvePercentTax = order.items.filter(
    (item) => item.taxRate === 0.12 && item.status !== "refunded"
  );
  const twentyFivePercentTax = order.items.filter(
    (item) => item.taxRate === 0.25 && item.status !== "refunded"
  );

  const calculateTotalTax = (items) => {
    return items.reduce((total, item) => total + item.taxAmount, 0);
  };

  const totalTaxAmount =
    calculateTotalTax(sixPercentTax) +
    calculateTotalTax(tenPercentTax) +
    calculateTotalTax(twelvePercentTax) +
    calculateTotalTax(twentyFivePercentTax);

  const totalSixPercentTax = calculateTotalTax(sixPercentTax);
  const totalTenPercentTax = calculateTotalTax(tenPercentTax);
  const totalTwelvePercentTax = calculateTotalTax(twelvePercentTax);
  const totalTwentyFivePercentTax = calculateTotalTax(twentyFivePercentTax);
  let currency = "kr";
  if (order.currency === null) {
    if (language !== "sv-SE") {
      currency = "SEK";
    }
  } else {
    if (order.currency === "SEK") {
      if (language !== "sv-SE") {
        currency = "SEK";
      } else {
        currency = "kr";
      }
    } else {
      if (order.currency === "EUR") {
        currency = "EUR";
      }
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="p-3">
            <p className="text-l p-0 m-0">
              <strong>
                {" "}
                {translateComponent(
                  translations,
                  language,
                  "taxComponent",
                  "total"
                )}
                :
              </strong>{" "}
              {order.currentAmount} {currency}
            </p>
            <div className="">
              {" "}
              <p className="text-m p-0 m-0">
                <strong>
                  {translateComponent(
                    translations,
                    language,
                    "taxComponent",
                    "totalTax"
                  )}
                  :
                </strong>{" "}
                {totalTaxAmount} {currency}
              </p>
              {sixPercentTax.length > 0 ? (
                <p className="text-m p-0 m-0 text-muted ps-3">
                  <strong>
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "taxComponent",
                      "totalSixPercentTaxText"
                    )}
                  </strong>
                  : {totalSixPercentTax} {currency}
                </p>
              ) : null}
              {tenPercentTax.length > 0 ? (
                <p className="text-m p-0 m-0 text-muted">
                  <strong>
                    {translateComponent(
                      translations,
                      language,
                      "taxComponent",
                      "totalTenPercentTaxText"
                    )}
                  </strong>
                  : :{totalTenPercentTax} {currency}
                </p>
              ) : null}
              {twelvePercentTax.length > 0 ? (
                <p className="text-m p-0 m-0 text-muted">
                  <strong>
                    {translateComponent(
                      translations,
                      language,
                      "taxComponent",
                      "totalTwelvePercentTaxText"
                    )}
                  </strong>
                  : {totalTwelvePercentTax} {currency}
                </p>
              ) : null}
              {twentyFivePercentTax.length > 0 ? (
                <p className="text-m p-0 m-0 text-muted">
                  <strong>
                    {translateComponent(
                      translations,
                      language,
                      "taxComponent",
                      "totalTwentyFivePercentTaxText"
                    )}
                  </strong>
                  : {totalTwentyFivePercentTax} {currency}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxComponent;

/* global Dibs */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { translatePage } from "../../utils/services/translate";

// helpers
import { getPreferedLanguage } from "../../utils/localStorage";

// scss
import "./netsCheckoutPage.scss";

function NetsCheckoutPage() {
  const navigate = useNavigate();
  const { paymentId, referenceId } = useParams();
  const translations = useSelector((state) => state.Layout.translations);
  const language = useSelector((state) => state.Layout.language);

  useEffect(() => {
    const initializeDibsCheckout = () => {
      let lang = getPreferedLanguage();
      if (
        getPreferedLanguage() === undefined ||
        getPreferedLanguage() === null
      ) {
        lang = "en-GB";
      }

      const checkoutOptions = {
        checkoutKey: String(process.env.REACT_APP_NETS_CHECKOUT_KEY),
        paymentId: paymentId,
        containerId: "checkout-container-div",
        language: lang,
        theme: {
          buttonRadius: "25px",
        },
      };

      const checkout = new Dibs.Checkout(checkoutOptions);
      checkout.on("payment-completed", function (response) {
        let redirectUrl = "/t/" + referenceId;
        navigate(redirectUrl);
      });
      checkout.on("pay-initialized", function (paymentId) {
        checkout.send("payment-order-finalized", true);
      });
    };

    window.scrollTo(0, 0);
    const script1 = document.createElement("script");
    script1.src = String(process.env.REACT_APP_NETS_CHECKOUT_URI);
    script1.async = true;
    script1.onload = initializeDibsCheckout;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, [navigate, paymentId, referenceId]);
  return (
    <div className="container mt-3 min-height-100">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center mt-5">
            <button
              className="btn btn-secondary mt-2 cancelbutton"
              onClick={() => {
                navigate("/paymentcheck");
              }}
            >
              {translatePage(
                translations,
                language,
                "netsCheckoutPage",
                "cancelButtonText"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="checkout-container-div" data-payment-id={paymentId}></div>
      </div>
    </div>
  );
}

export default NetsCheckoutPage;

export function Logo() {
  return (
    <a className="logo ml-4 d-block d-print-none" href="/">
      <svg
        width="76"
        height="39"
        viewBox="0 0 76 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.3195 11.3585C38.3993 10.3259 39.2775 9.53158 40.3153 9.61101C41.3531 9.69044 42.1514 10.5642 42.0716 11.5967C41.9917 12.6293 41.1136 13.4236 40.0758 13.3442C39.038 13.2648 38.2396 12.391 38.3195 11.3585Z"
          fill="white"
        ></path>
        <path
          d="M12.4538 10.8819V24.5438C12.4538 24.6232 12.374 24.6232 12.374 24.6232H9.42017C9.10084 24.6232 8.70168 24.4643 8.54202 24.1466L3.35294 16.6802V24.6232C3.35294 24.6232 3.35294 24.6232 3.27311 24.6232H0.0798319C0.0798319 24.6232 0 24.6232 0 24.5438V10.8819C0 10.8024 0.0798319 10.8024 0.0798319 10.8024H2.95378C3.35294 10.8024 3.67227 10.9613 3.83193 11.279L9.02101 19.222V10.8819C9.02101 10.8024 9.10084 10.8024 9.10084 10.8024H12.2941C12.4538 10.8024 12.4538 10.8024 12.4538 10.8819Z"
          fill="white"
        ></path>
        <path
          d="M37.0422 19.4603C37.0422 22.4786 35.2061 24.7821 32.2523 24.7821C30.8951 24.7821 29.6178 24.2261 29.1388 23.4318L28.8195 24.0672C28.6598 24.4643 28.2607 24.6232 27.8615 24.6232H26.1052C26.0254 24.6232 26.0254 24.5438 26.0254 24.5438V10.8819C26.0254 10.8024 26.1052 10.8024 26.1052 10.8024H29.2187C29.2985 10.8024 29.2985 10.8819 29.2985 10.8819V15.4094C29.6977 14.6945 30.8153 14.1385 32.1724 14.1385C35.2859 14.1385 37.0422 16.4419 37.0422 19.4603ZM33.7691 19.4603C33.7691 18.0305 32.8909 16.9979 31.6136 16.9979C30.2565 16.9979 29.3783 17.9511 29.3783 19.4603C29.3783 20.89 30.2565 21.9226 31.6136 21.9226C32.8909 21.8432 33.7691 20.89 33.7691 19.4603Z"
          fill="white"
        ></path>
        <path
          d="M59.7142 14.1385C61.0714 14.1385 62.189 14.6945 62.5882 15.4094V14.1385C62.5882 14.0591 62.668 14.0591 62.668 14.0591H65.7815C65.8613 14.0591 65.8613 14.1385 65.8613 14.1385V24.5438C65.8613 24.6232 65.7815 24.6232 65.7815 24.6232H64.0252C63.626 24.6232 63.2268 24.3849 63.0672 23.9878L62.8277 23.4318C62.3487 24.2261 61.0714 24.7821 59.7142 24.7821C56.7605 24.7821 54.9243 22.4786 54.9243 19.4603C54.9243 16.4419 56.6806 14.1385 59.7142 14.1385ZM60.4327 21.8432C61.7899 21.8432 62.668 20.89 62.668 19.3808C62.668 17.9511 61.7899 16.9185 60.4327 16.9185C59.1554 16.9185 58.2773 17.8717 58.2773 19.3808C58.1974 20.89 59.0756 21.8432 60.4327 21.8432Z"
          fill="white"
        ></path>
        <path
          d="M53.4075 18.11V24.5438C53.4075 24.6232 53.3276 24.6232 53.3276 24.6232H50.2142C50.1344 24.6232 50.1344 24.5438 50.1344 24.5438V18.5866C50.1344 17.4745 49.4159 17.0774 48.6175 17.0774C47.8192 17.0774 46.9411 17.6334 46.9411 18.9837V24.6232C46.9411 24.7026 46.8612 24.7026 46.8612 24.7026H43.7478C43.668 24.7026 43.668 24.6232 43.668 24.6232V14.4562C43.668 14.3768 43.7478 14.3768 43.7478 14.3768H45.6638C46.0629 14.3768 46.3823 14.6151 46.6218 14.9328L46.9411 15.4094C47.4999 14.6151 48.6175 14.1385 49.815 14.1385C51.731 14.1385 53.4075 15.3299 53.4075 18.11Z"
          fill="white"
        ></path>
        <path
          d="M19.2394 14.1385C16.2856 14.1385 13.8906 16.5214 13.8906 19.4603C13.8906 22.3992 16.2856 24.7821 19.2394 24.7821C22.1931 24.7821 24.5881 22.3992 24.5881 19.4603C24.5881 16.5214 22.1931 14.1385 19.2394 14.1385ZM19.2394 21.6843C18.0419 21.6843 17.0839 20.6517 17.0839 19.3809C17.0839 18.11 18.0419 17.0774 19.2394 17.0774C20.4368 17.0774 21.3948 18.11 21.3948 19.3809C21.3948 20.7312 20.4368 21.6843 19.2394 21.6843Z"
          fill="white"
        ></path>
        <path
          d="M38.6389 14.3768H41.7524C41.8322 14.3768 41.8322 14.4562 41.8322 14.4562V24.5438C41.8322 24.6232 41.7524 24.6232 41.7524 24.6232H38.6389C38.5591 24.6232 38.5591 24.5438 38.5591 24.5438V14.4562C38.5591 14.3768 38.5591 14.3768 38.6389 14.3768Z"
          fill="white"
        ></path>
        <path
          d="M76 19.4603C76 30.1039 67.458 38.7617 56.7605 38.9206C48.6975 39 41.6723 34.2342 38.6387 27.3238C38.6387 27.2444 38.6387 27.2444 38.7185 27.2444H41.9118C41.9916 27.2444 41.9916 27.2444 42.0714 27.3238C44.7857 32.4073 50.2941 35.9022 56.6807 35.8228C65.7815 35.7434 73.1261 28.277 73.0462 19.222C72.8866 10.2464 65.542 3.01833 56.521 3.01833C51.0925 3.01833 46.2227 5.63951 43.2689 9.69043H43.1891C42.7101 8.89613 41.8319 8.26069 40.7941 8.10183C40.7143 8.10183 40.7143 8.0224 40.7143 8.0224C44.2269 3.17719 49.9748 0 56.521 0C67.2983 0 76 8.73727 76 19.4603Z"
          fill="#39A935"
        ></path>
      </svg>
    </a>
  );
}

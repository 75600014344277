// @flow
import { LayoutActionTypes } from "./constants";

type LayoutAction = { type: string, payload?: string | null };

export const setLoading = (bool): LayoutAction => ({
  type: LayoutActionTypes.SET_LOADING,
  payload: bool,
});
export const setLoadingMessage = (sv, en): LayoutAction => ({
  type: LayoutActionTypes.SET_LOADING_MESSAGE,
  payload: { sv: sv, en: en },
});
export const removeLoadingMessage = (): LayoutAction => ({
  type: LayoutActionTypes.REMOVE_LOADING_MESSAGE,
  payload: null,
});
export const setShowNavigationMenu = (bool): LayoutAction => ({
  type: LayoutActionTypes.SHOW_NAVIGATION_MENU,
  payload: bool,
});
export const setDisableNavigation = (bool): LayoutAction => ({
  type: LayoutActionTypes.DISABLE_NAVIGATION,
  payload: bool,
});

export const setLanguage = (language) => ({
  type: LayoutActionTypes.SET_LANGUAGE,
  payload: language,
});

export const setTranslations = (translations) => ({
  type: LayoutActionTypes.SET_TRANSLATIONS,
  payload: translations,
});

export const setShowLanguageMenu = (bool) => ({
  type: LayoutActionTypes.SET_SHOW_LANGUAGE_MENU,
  payload: bool,
});
export const setLayoutShowErrorMessage = (header, text) => ({
  type: LayoutActionTypes.SET_SHOW_ERROR_MESSAGE,
  payload: { header: header, text: text },
});
export const setLayoutShowError = (bool) => ({
  type: LayoutActionTypes.SET_SHOW_ERROR,
  payload: bool,
});
export const setLanguageOptions = (options) => ({
  type: LayoutActionTypes.SET_LANGUAGE_OPTIONS,
  payload: options,
});

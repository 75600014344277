import { useSelector } from "react-redux";
import { translateComponent } from "../../utils/services/translate";

export function TicketErrorDialog({ isOpen, onClose }) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  if (!isOpen) return null;

  return (
    <div className="confirmation-dialog text-dark bg-light p-3">
      <h2>
        {" "}
        {translateComponent(translations, language, "ticket", "errorDialogh2")}
      </h2>
      <p>
        {" "}
        {translateComponent(translations, language, "ticket", "errorDialogp1")}.
      </p>
      <p>
        {" "}
        {translateComponent(translations, language, "ticket", "errorDialogp2")}
      </p>
      <button className="btn btn-secondary w-100 mb-3" onClick={onClose}>
        {translateComponent(
          translations,
          language,
          "ticket",
          "errorDialogButton"
        )}
      </button>
    </div>
  );
}

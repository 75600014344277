import tokenClient from "./tokenClient/tokenClient";
import protectedClient from "./protectedClient";

export const protectedApiRequest = async (apiFunction) => {
  try {
    const response = await apiFunction();
    if (response.status === 401) {
      handleUnauthorized();
    }
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
    console.error("Något gick fel.");
  }
};

export const protectedApiRequestV2 = async (apiFunction) => {
  try {
    const response = await apiFunction();
    if (response.status === 401) {
      handleUnauthorized();
    }
    return response.data;
  } catch (error) {
    handleUnauthorized();
  }
};

const handleUnauthorized = () => {
  // Todo: check if token is revoked.
};

export const postPasscode = async (phoneNumber, verificationCode) => {
  try {
    const bodyObject = {
      phoneNumber,
      verificationCode,
    };

    const response = await protectedClient.post(
      "/api/identity/login/passcode",
      bodyObject
    );
    if (response.status === 200) {
      localStorage.setItem("tokenExpireAt", response.data.tokenExpireDateTime);
      localStorage.setItem(
        "refreshTokenExpireAt",
        response.data.refreshTokenExpireDateTime
      );
      localStorage.setItem("phoneNumber", phoneNumber);
    }

    return response.data;
  } catch (error) {
    console.error("Inloggningsfel", error);

    if (error.response) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const loginRefresh = async () => {
  try {
    const response = await tokenClient.post("/api/identity/login/refresh");
    localStorage.setItem("tokenExpireAt", response.data.tokenExpireDateTime);
    localStorage.setItem(
      "refreshTokenExpireAt",
      response.data.refreshTokenExpireDateTime
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("tokenExpireAt");
      localStorage.removeItem("refreshTokenExpireAt");
      localStorage.removeItem("phoneNumber");
      window.location.href = "/login";
    }
  }
};

export const loginRefreshV2 = async () => {
  try {
    const response = await tokenClient.post("/api/identity/login/refresh");
    localStorage.setItem("tokenExpireAt", response.data.tokenExpireDateTime);
    localStorage.setItem(
      "refreshTokenExpireAt",
      response.data.refreshTokenExpireDateTime
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("tokenExpireAt");
      localStorage.removeItem("refreshTokenExpireAt");
      localStorage.removeItem("phoneNumber");
      window.location.href = "/paymentcheck/login";
    }
  }
};

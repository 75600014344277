export const LayoutActionTypes = {
  SET_LOADING_MESSAGE: "@@layout/SET_LOADING_MESSAGE",
  REMOVE_LOADING_MESSAGE: "@@layout/REMOVE_LOADING_MESSAGE",
  SET_LOADING: "@@layout/SET_LOADING",
  SHOW_NAVIGATION_MENU: "@@layout/SHOW_NAVIGATION_MENU",
  DISABLE_NAVIGATION: "@@layout/DISABLE_NAVIGATION",
  SET_LANGUAGE: "@@layout/SET_LANGUAGE",
  SET_TRANSLATIONS: "@@layout/SET_TRANSLATIONS",
  SET_SHOW_LANGUAGE_MENU: "@@layout/SET_SHOW_LANGUAGE_MENU",
  SET_SHOW_ERROR: "@@layout/SET_SHOW_ERROR",
  SET_SHOW_ERROR_MESSAGE: "@@layout/SET_SHOW_ERROR_MESSAGE",
  SET_LANGUAGE_OPTIONS: "@@layout/SET_LANGUAGE_OPTIONS",
};

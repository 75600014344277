import axios from "axios";

const tokenClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URI,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export default tokenClient;

import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../redux/actions";

// CSS
import "./Navigation.scss";

// Actions
import { setShowLanguageMenu } from "../../redux/actions";
import { setPreferedLanguage } from "../../utils/localStorage";

// Redux
import { useSelector } from "react-redux";

// Utils
import {
  getPreferedLanguage,
  getLanguageOptions,
} from "../../utils/localStorage";

const toggleLanguage = (dispatch, lang) => {
  dispatch(setLanguage(lang));
  setPreferedLanguage(lang);
  dispatch(setShowLanguageMenu(false));
};

function LanguageMenu(props) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);

  let preferedLanguage = getPreferedLanguage();
  let langOptions = getLanguageOptions();

  const dispatch = useDispatch();
  if (props.show_languague_menu && !props.disable_navigation) {
    return (
      <React.Fragment>
        <div className="navigation min-height-100">
          <div className="menu-container pl-4">
            <div className="menu-container-content">
              <div className="list-menu pt-3">
                <div className="list-item m-4">
                  <h2 className="text-dark">Byt språk / Switch language</h2>
                  <div className="pt-4 border-green border-b h-50"></div>
                </div>

                {langOptions.map((item, index) => {
                  if (item === "sv-SE") {
                    if (preferedLanguage === "sv-SE") {
                      return (
                        <div className="list-item m-4 ">
                          <button
                            langOptions
                            className="btn text-lang-button-selected text-lg p-2 "
                            onClick={() => toggleLanguage(dispatch, item)}
                          >
                            Svenska / Swedish
                          </button>
                          <div className="pt-4 border-green border-b h-50"></div>
                        </div>
                      );
                    }
                    return (
                      <div className="list-item m-4 ">
                        <button
                          langOptions
                          className="btn text-lang-button text-lg p-2 "
                          onClick={() => toggleLanguage(dispatch, item)}
                        >
                          Svenska / Swedish
                        </button>
                        <div className="pt-4 border-green border-b h-50"></div>
                      </div>
                    );
                  }
                  if (item === "en-US") {
                    if (preferedLanguage === "en-US") {
                      return (
                        <div className="list-item m-4 ">
                          <button
                            langOptions
                            className="btn text-lang-button-selected text-lg p-2 "
                            onClick={() => toggleLanguage(dispatch, item)}
                          >
                            Engelska / English
                          </button>
                          <div className="pt-4 border-green border-b h-50"></div>
                        </div>
                      );
                    }
                    return (
                      <div className="list-item m-4 ">
                        <button
                          langOptions
                          className="btn text-lang-button d text-lg p-2 "
                          onClick={() => toggleLanguage(dispatch, item)}
                        >
                          Engelska / English
                        </button>
                        <div className="pt-4 border-green border-b h-50"></div>
                      </div>
                    );
                  }
                  if (item === "fi-FI") {
                    if (preferedLanguage === "fi-FI") {
                      return (
                        <div className="list-item m-4 ">
                          <button
                            langOptions
                            className="btn text-lang-button-selected text-lg p-2 "
                            onClick={() => toggleLanguage(dispatch, item)}
                          >
                            Finska / Finnish
                          </button>
                          <div className="pt-4 border-green border-b h-50"></div>
                        </div>
                      );
                    }
                    return (
                      <div className="list-item m-4 ">
                        <button
                          langOptions
                          className="btn text-lang-button d text-lg p-2 "
                          onClick={() => toggleLanguage(dispatch, item)}
                        >
                          Finska / Finnish
                        </button>
                        <div className="pt-4 border-green border-b h-50"></div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 p-4 text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

function mapStateToProps({ Layout }) {
  return {
    show_languague_menu: Layout.show_languague_menu,
    language: Layout.language,
    disable_navigation: Layout.disable_navigation,
  };
}
export default connect(mapStateToProps)(LanguageMenu);

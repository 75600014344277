// @flow
import { OrderActionTypes } from "./constants";

const INIT_STATE = {
  selected_order: null,
  selected_order_refund_status: false,
  basket: {
    basketValid: false,
    phoneValid: false,
    phone: "",
    total: 0,
    products: [],
  },
  phone_number_friendly_name: null,
  failed_to_send_order: false,
  order_not_found: false,
  order_list: null,
  order_list_selected_id: null,
};

type OrderAction = { type: string, payload?: string | null };

type State = {};

const Order = (state: State = INIT_STATE, action: OrderAction): any => {
  switch (action.type) {
    case "RESET_STATE":
      return {
        selected_order: null,
        selected_order_refund_status: false,
        basket: {
          basketValid: false,
          phoneValid: false,
          phone: "",
          total: 0,
          products: [],
        },
        phone_number_friendly_name: null,
        failed_to_send_order: false,
        order_not_found: false,
        order_list: null,
        order_list_selected_id: null,
      };
    case OrderActionTypes.ADD_TO_BASKET:
      return {
        ...state,
        basket: action.payload,
      };
    case OrderActionTypes.SET_FRIENDLY_NAME_NUMBER:
      return {
        ...state,
        phone_number_friendly_name: action.payload,
      };

    case OrderActionTypes.SET_FAILED_TO_SEND_ORDER:
      return {
        ...state,
        failed_to_send_order: action.payload,
      };
    case OrderActionTypes.SET_SELECTED_ORDER:
      return {
        ...state,
        selected_order: action.payload,
      };
    case OrderActionTypes.SET_SELECTED_ORDER_REFUND_STATUS:
      return {
        ...state,
        selected_order_refund_status: action.payload,
      };
    case OrderActionTypes.SET_ORDER_NOT_FOUND:
      return {
        ...state,
        order_not_found: action.payload,
      };
    case OrderActionTypes.SET_ORDER_LIST:
      return {
        ...state,
        order_list: action.payload,
      };
    case OrderActionTypes.SET_ORDER_LIST_SELECTED_ID:
      return {
        ...state,
        order_list_selected_id: action.payload,
      };
    default:
      return state;
  }
};

export default Order;

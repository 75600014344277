// spinner
import HashLoader from "react-spinners/HashLoader";
import "./LoadingComponent.css";
import { useSelector } from "react-redux";

export function LoadingComponent() {
  const messageSv = useSelector((state) => state.Layout.loading_message_sv);
  const loading = useSelector((state) => state.Layout.loading);
  const messageEn = useSelector((state) => state.Layout.loading_message_en);
  const language = useSelector((state) => state.Layout.language);
  const style = { textAlign: "center" };
  const spinnerStyle = {
    paddingLeft: "30px",
    paddingTop: "50vh",
    display: "block",
  };
  return (
    <>
      <div className="container mt-5 pt-5 min-height-100">
        <div className="row" style={style}>
          <div className="col-12 text-center">
            <HashLoader
              style={spinnerStyle}
              color={"#39a935"}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div id="loading-message">
        {language === "sv-SE" ? (
          <p className="mt-3">{messageSv}</p>
        ) : (
          <p className="mt-3">{messageEn}</p>
        )}
      </div>
    </>
  );
}

// @flow
import { LoginActionTypes } from "./constants";

const INIT_STATE = {
  sms_sent: false,
  sms_sent_failure: false,
  phone_valid: false,
  phone_char: 0,
  verification_code_valid: false,
  verification_code_not_accepted: false,
  verification_code: "",
  verification_code_length: 0,
  phone_number: "",
};

type LoginAction = { type: string, payload?: string | null };

type State = {};

const Login = (state: State = INIT_STATE, action: LoginAction): any => {
  switch (action.type) {
    case LoginActionTypes.SET_SMS_SENT:
      return {
        ...state,
        sms_sent: action.payload,
      };
    case LoginActionTypes.SET_PHONE_NUMBER:
      return {
        ...state,
        phone_number: action.payload,
      };
    case LoginActionTypes.SET_PHONE_VALID:
      return {
        ...state,
        phone_valid: action.payload,
      };
    case LoginActionTypes.SET_PHONE_CHAR:
      return {
        ...state,
        phone_car: action.payload,
      };
    case LoginActionTypes.SET_VERIFICATION_CODE:
      return {
        ...state,
        verification_code: action.payload,
      };
    case LoginActionTypes.SET_VERIFICATION_CODE_VALID:
      return {
        ...state,
        verification_code_valid: action.payload,
      };
    case LoginActionTypes.SET_VERIFICATION_CODE_LENGTH:
      return {
        ...state,
        verification_code_length: action.payload,
      };
    case LoginActionTypes.SET_VERIFICATION_CODE_NOT_ACCEPTED:
      return {
        ...state,
        verification_code_not_accepted: action.payload,
      };
    case LoginActionTypes.SET_SMS_SENT_FAILURE:
      return {
        ...state,
        sms_sent_failure: action.payload,
      };
    case LoginActionTypes.RESET_LOGIN_STATES:
      return {
        ...state,
        sms_sent: false,
        sms_sent_failure: false,
        phone_valid: false,
        phone_char: 0,
        verification_code_not_accepted: false,
        verification_code_valid: false,
        verification_code: "",
        verification_code_length: 0,
        phone_number: "",
      };
    default:
      return state;
  }
};

export default Login;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CookieConsent.scss";

import englishFlag from "../../assets/img/lang/english_flag.png";

function CookieConsent() {
  const [visible, setVisible] = useState(
    !localStorage.getItem("cookieConsent")
  );
  const [expanded, setExpanded] = useState(false);
  const [language, setLanguage] = useState("sv");

  const consentMessage = {
    sv: "Vi använder endast cookies som säkerställer webbplatsens funktion. Om du inte accepterar cookies kommer webbplatsen inte att fungera som det är tänkt. Genom att acceptera cookies samtycker du till vår användning av cookies. Mer information om våra cookies finner du ",
    eng: "We use cookies for functionality purposes only for this website to work properly. If you reject acceptance, the website services will not work as intended. By accepting the use of cookies, you consent to all our cookies for this website. More information about our cookies is found ",
  };
  const consentMessage2 = {
    sv: "här",
    eng: "here",
  };
  const consentMessage3 = {
    sv: ", och hur vi behandlar din data hittar du i",
    eng: ", and how we handle your data is found in our ",
  };
  const consentMessage4 = {
    sv: "Nobinas integritetspolicy",
    eng: "privacy policy.",
  };
  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setVisible(false);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  function switchLanguage() {
    setLanguage(language === "sv" ? "eng" : "sv");
  }

  return (
    visible && (
      <div className={"cookieConsent"}>
        <div className="row">
          <div className="col-12 text-center">
            {language === "sv" ? (
              <button onClick={switchLanguage} className="btn btn-none text-m">
                <img src={englishFlag} alt="English flag" className="me-2" />
                <span className="text-m">For English click here</span>
              </button>
            ) : (
              <button
                onClick={switchLanguage}
                className="btn btn-swedish  btn-none text-m"
              >
                <span className="text-m ms-3">För svenska klicka här</span>
              </button>
            )}
          </div>
          <div className="col-12 col-md-8">
            <p className="p-0 text-m">
              {consentMessage[language]}{" "}
              <Link className="text-m" to="/cookies">
                {consentMessage2[language]}
              </Link>
              {consentMessage3[language]}{" "}
              <a
                href="https://www.nobina.se/om-oss/dataskydd-och-integritet/personuppgiftsbehandling"
                target="_blank"
                rel="noopener noreferrer"
                className="pt-3 text-m"
              >
                {consentMessage4[language]}{" "}
              </a>
            </p>
          </div>
          <div className="col-12 col-md-4 text-end">
            {language === "sv" ? (
              <>
                <button
                  className="btn btn-secondary w-100 text-m"
                  onClick={() => {
                    acceptCookies();
                  }}
                >
                  Ja, jag accepterar
                </button>
                <Link to="/cookies" className="btn btn-none w-100 text-m">
                  Nej tack, jag accepterar inte
                </Link>
              </>
            ) : (
              <>
                <button
                  className="btn btn-secondary w-100 text-m"
                  onClick={() => {
                    acceptCookies();
                  }}
                >
                  Yes, I accept
                </button>
                <Link to="/cookies" className="btn btn-none w-100 text-m">
                  No thanks, I don't accept
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default CookieConsent;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getDefaultProject } from "../utils/localStorage";
import { LoadingComponent } from "../components/layout/loading/LoadingComponent";
import EmptyPageLang from "./EmptyPageLang";
import "./EmptyPage.scss";

function EmptyPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("sv-SE");

  const handleDefaultProject = useCallback(async () => {
    const project = getDefaultProject();
    if (project) {
      setLoading(false);
      navigate(project.slug);
    } else {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    handleDefaultProject();
  }, [handleDefaultProject]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const projects = [
    {
      title: "Sweden Rock",
      descriptions: {
        "sv-SE":
          "Nobina är med på rockfesten med sina festivalbussar under Sweden Rock 2024 igen! Alla festivalbesökare som vill resa med buss till och från festivalområdet på ett hållbart och ekonomiskt smart sätt kommer kunna göra det med Nobinas pendlarbussar. ",
        "en-US":
          "We will operate our popular festival buses during the 2024 Sweden Rock Festival! All festival visitors who want to travel by bus to and from the festival venue will be able to do so in a sustainable and financial smart manner onboard Nobina's shuttle buses.",
        "fi-FI":
          "We will operate our popular festival buses during the 2024 Sweden Rock Festival! All festival visitors who want to travel by bus to and from the festival venue will be able to do so in a sustainable and financial smart manner onboard Nobina's shuttle buses.",
      },
      slug: "/swedenrock",
      button_texts: {
        "sv-SE": "Köp biljetter",
        "en-US": "Buy tickets",
        "fi-FI": "buy tickets",
      },
    },
    {
      title: "Saaristo",
      descriptions: {
        "sv-SE":
          "Res till Saaristo med våra bussbiljetter och upplev skärgården.",
        "en-US":
          "Travel to Saaristo with our bus tickets and experience the archipelago.",
        "fi-FI":
          "Matkusta Saaristoon bussilipuillamme ja koe saariston kauneus.",
      },
      slug: "/saaristo",
      button_texts: {
        "sv-SE": "Köp biljetter",
        "en-US": "Buy tickets",
        "fi-FI": "Osta liput",
      },
    },
  ];

  if (loading) return <LoadingComponent />;

  return (
    <div className="container min-vh-100 mt-5 pt-5 text-center">
      <h1>Nobina-Biljetten</h1>
      <EmptyPageLang onLanguageChange={handleLanguageChange} />
      <div className="row justify-content-center">
        {projects.map((project, index) => (
          <div key={index} className="col-md-4 mb-4 d-flex">
            <div
              className="card card-equal-height"
              style={{ borderRadius: "15px", border: "2px solid green" }}
            >
              <div className="card-body">
                <h2 className="card-title">{project.title}</h2>
                <p className="card-text">{project.descriptions[language]}</p>
                <button
                  className="btn btn-success mt-auto"
                  onClick={() => navigate(project.slug)}
                >
                  {project.button_texts[language]}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmptyPage;

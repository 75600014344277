import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Importing images
import swedishFlag from "../../assets/img/lang/sv.svg";
import finnishFlag from "../../assets/img/lang/fin.svg";
import englishFlag from "../../assets/img/lang/eng.svg";

// LocalStorage
import {
  setPreferedLanguage,
  getLanguageOptions,
} from "../../utils/localStorage";

import { setLanguage } from "../../redux/actions";

const LanguageComponent = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Layout.language);
  const loading = useSelector((state) => state.Layout.loading);
  const langOptions = getLanguageOptions();

  if (loading || !langOptions || langOptions.length === 0) {
    return null;
  }

  const toggleLanguage = (lang) => {
    dispatch(setLanguage(lang));
    setPreferedLanguage(lang);
  };

  let langOptionsText = langOptions.map((element) => {
    switch (element) {
      case "sv-SE":
        return "Byt språk";
      case "fi-FI":
        return "Vaihda kieltä";
      case "en-US":
        return "Switch language";
      default:
        return "";
    }
  });

  const finalText = langOptionsText.join(" / ");

  return (
    <div id="lang-component" className="pb-3">
      {langOptions.map((item, index) => {
        const flagMap = {
          "sv-SE": swedishFlag,
          "fi-FI": finnishFlag,
          "en-US": englishFlag,
        };
        const altTextMap = {
          "sv-SE": "Swedish flag",
          "fi-FI": "Finnish flag",
          "en-US": "English flag",
        };

        return (
          <img
            key={index}
            src={flagMap[item]}
            alt={altTextMap[item]}
            className={`mr-4 pr-4 lang-flag ${item}_flag ${
              language === item ? "lang-selected" : ""
            }`}
            onClick={() => toggleLanguage(item)}
          />
        );
      })}
      <p className="text-m pb-0 mb-1">{finalText}</p>
    </div>
  );
};

export default LanguageComponent;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useSelector } from "react-redux";

//components
import CountDownTicket from "./countDownTicket";
// utils
import { translateComponent } from "../../utils/services/translate";

// scss
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ticketListCarusell.scss";

function TicketListCarusell(props) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const tickets = useSelector((state) => state.Ticket.ticket_list);

  if (tickets !== null && tickets.length > 0) {
    let sortedList = tickets.sort(
      (a, b) =>
        moment(b.expirationDateTime).valueOf() -
        moment(a.expirationDateTime).valueOf()
    );

    return (
      <div className="ticket-carousel-sm carousel-container bg-green w-100 w-none-sm">
        <Carousel showThumbs={false}>
          {sortedList.map((ticket) => {
            if (ticket.validationType === "countdown") {
              return (
                <CountDownTicket
                  key={ticket.referenceId}
                  ticket={ticket}
                ></CountDownTicket>
              );
            }
            return null;
          })}
        </Carousel>
      </div>
    );
  } else {
    return (
      <p className="text-dark text-center bg-light p-3">
        {translateComponent(
          translations,
          language,
          "ticketListCarusell",
          "no_active_tickets"
        )}
      </p>
    );
  }
}

export default TicketListCarusell;

// @flow
import { OrderActionTypes } from "./constants";

type OrderAction = { type: string, payload?: string | null };

export const setBasketData = (data): OrderAction => ({
  type: OrderActionTypes.ADD_TO_BASKET,
  payload: data,
});
export const setFriendlyNameNumber = (data): OrderAction => ({
  type: OrderActionTypes.SET_FRIENDLY_NAME_NUMBER,
  payload: data,
});
export const setSelectedOrder = (data): OrderAction => ({
  type: OrderActionTypes.SET_SELECTED_ORDER,
  payload: data,
});
export const setOrderNotFound = (data): OrderAction => ({
  type: OrderActionTypes.SET_ORDER_NOT_FOUND,
  payload: data,
});
export const setOrderList = (data): OrderAction => ({
  type: OrderActionTypes.SET_ORDER_LIST,
  payload: data,
});
export const setOrderListSelectedId = (number): OrderAction => ({
  type: OrderActionTypes.SET_ORDER_LIST_SELECTED_ID,
  payload: number,
});
export const setFailedToSendOrder = (bool): OrderAction => ({
  type: OrderActionTypes.SET_FAILED_TO_SEND_ORDER,
  payload: bool,
});
export const setSelectedOrderRefundStatus = (bool): OrderAction => ({
  type: OrderActionTypes.SET_SELECTED_ORDER_REFUND_STATUS,
  payload: bool,
});

// @flow
import { ProjectActionTypes } from "./constants";

type ProjectAction = { type: string, payload?: string | null };

export const setProjectList = (data): ProjectAction => ({
  type: ProjectActionTypes.SET_PROJECT_LIST,
  payload: data,
});

export const filterSelectedProjectsByCategory = (name): ProjectAction => ({
  type: ProjectActionTypes.SET_SELECTED_PROJECTS_BY_CATEGORY,
  payload: name,
});

export const setSelectedProjectSlug = (data): ProjectAction => ({
  type: ProjectActionTypes.SET_SELECTED_PROJECT_SLUG,
  payload: data,
});

export const setSelectedProject = (data): ProjectAction => ({
  type: ProjectActionTypes.SET_SELECTED_PROJECT,
  payload: data,
});

export const setMyProjectList = (data): ProjectAction => ({
  type: ProjectActionTypes.SET_MY_PROJECT_LIST,
  payload: data,
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

// components
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";

// Api
import { postLogin } from "../../utils/api/restClient";

// redux actions
import {
  setSmsSent,
  setPhoneNumber,
  setPhoneChar,
  setPhoneValid,
  setSmsSentFailure,
  setVerificationCode,
  setVerificationCodeValid,
  setVerificationCodeLength,
  setDisableNavigation,
  setLoading,
} from "../../redux/actions";

// utils
import { translatePage } from "../../utils/services/translate";

function LoginPageWithRedirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginRedirect } = useAuth();

  const {
    sms_sent,
    sms_sent_failure,
    phone_valid,
    phone_number,
    loading,
    verification_code_length,
    verification_code,
    verification_code_valid,
    verification_code_not_accepted,
  } = useSelector((state) => state.Login);
  const phoneNumberFriendlyName = useSelector(
    (state) => state.Order.phone_number_friendly_name
  );
  const phoneNumber = useSelector((state) => state.Order.basket.phone);
  const basket = useSelector((state) => state.Order.basket);
  const { language, translations } = useSelector((state) => state.Layout);
  const [errorOpen, setErrorOpen] = useState(false);

  function handleErrorClose() {
    setErrorOpen(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setPhoneNumber(basket.phone));
  }, [dispatch]);

  function sendSMS(event, dispatch, phoneNumber) {
    event.preventDefault();

    dispatch(setSmsSentFailure(false));
    dispatch(setSmsSent(false));
    dispatch(setLoading(true));
    postLogin(phoneNumber).then((res) => {
      dispatch(setLoading(false));
      if (res.smsSent) {
        dispatch(setSmsSent(true));
      } else {
        setErrorOpen(true);
        dispatch(setSmsSent(false));
        dispatch(setSmsSentFailure(true));
      }
    });
  }
  function goToHomePage() {
    dispatch(setSmsSentFailure(false));
    dispatch(setSmsSent(false));
    dispatch(setLoading(false));
    navigate("/");
  }
  function handleCodeInput(event, current_code_length, dispatch) {
    var codeLength = event.target.value.length;
    dispatch(setVerificationCodeLength(codeLength));

    if (codeLength <= 6) {
      dispatch(setVerificationCode(event.target.value));
    }
    if (codeLength < 6) {
      dispatch(setVerificationCodeValid(false));
    } else {
      dispatch(setVerificationCodeValid(true));
    }
  }
  if (!basket.basketValid) {
    navigate("/");
  }
  if (loading) {
    return <>{LoadingComponent("Laddar..", loading)}</>;
  }
  if (sms_sent_failure) {
    // TODO: translate
    return (
      <div className="login-container">
        <div className="login-form bg-light p-4">
          <h3>
            {translatePage(
              translations,
              language,
              "loginWithRedirectPage",
              "smsSentFailure"
            )}{" "}
          </h3>
          <p>
            {" "}
            {translatePage(
              translations,
              language,
              "loginWithRedirectPage",
              "smsSentFailureQuestion1"
            )}{" "}
          </p>

          <p>
            {translatePage(
              translations,
              language,
              "loginWithRedirectPage",
              "smsSentFailureMessage1"
            )}{" "}
          </p>
          <button
            className="btn btn-secondary w-100 mt-4"
            onClick={() => {
              goToHomePage();
            }}
          >
            {translatePage(
              translations,
              language,
              "loginWithRedirectPage",
              "goHomeButtonText"
            )}{" "}
          </button>
        </div>
      </div>
    );
  }
  if (!sms_sent && !loading) {
    return (
      <div className="container min-height-100  d-flex justify-content-center align-items-center">
        <div className="phoneConfirmationNumberContainer pt-3">
          <div className="row">
            <div className="col-12 text-dark">
              <h1 className="text-center bg-light p-3">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "title"
                )}
              </h1>
              <h1 className="mt-3 text-center">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "description"
                )}
              </h1>
              <p className="phoneNumberConfirmationText text-extra-bold text-danger text-center">
                {phoneNumberFriendlyName}
              </p>
              <p className="pt-4">
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "message1"
                )}
              </p>
              <p className="">
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "message2"
                )}
              </p>
              <div className="col-12">
                <button
                  className="p-3 w-100 btn btn-success"
                  onClick={(event) => sendSMS(event, dispatch, phoneNumber)}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "yesButton"
                  )}
                </button>
              </div>
              <div className="col-12 mt-3">
                <button
                  className="p-3 w-100 btn btn-secondary"
                  onClick={() => navigate("/")}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "noButton"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (sms_sent) {
      return (
        <div className="login-container">
          <form className="login-form">
            <div className="bg-light">
              <h3 className="text-center p-3">
                {translatePage(
                  translations,
                  language,
                  "loginPage",
                  "codeTitle"
                )}
              </h3>
            </div>
            <div className="login-form-content">
              <p className="text-m text-center">
                {translatePage(
                  translations,
                  language,
                  "loginPage",
                  "codeDescription"
                )}{" "}
                {phoneNumber}
              </p>
              <div className="form-group ">
                <input
                  type="number"
                  className="form-control mt-1"
                  placeholder="xxx xxx"
                  value={verification_code}
                  onChange={(event) =>
                    handleCodeInput(event, verification_code_length, dispatch)
                  }
                />
              </div>
              {verification_code_not_accepted ? (
                <div className="alert alert-danger text-center mt-2">
                  {translatePage(
                    translations,
                    language,
                    "loginPage",
                    "errorWrongCode"
                  )}{" "}
                </div>
              ) : null}
              {verification_code_valid ? (
                <div className="d-grid gap-2 mt-3 mb-0">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={(event) => loginRedirect(event, "/paymentcheck")}
                  >
                    {translatePage(
                      translations,
                      language,
                      "loginPage",
                      "verifyButton"
                    )}{" "}
                  </button>
                </div>
              ) : (
                <div className="d-grid gap-2 mt-3 mb-0">
                  <button type="submit" className="btn btn-success" disabled>
                    {translatePage(
                      translations,
                      language,
                      "loginPage",
                      "verifyButton"
                    )}{" "}
                  </button>
                </div>
              )}
              <div className="d-grid gap-2 mt-3">
                <button className="btn btn-outline-secondary mt-4">
                  {" "}
                  {translatePage(
                    translations,
                    language,
                    "loginPage",
                    "cancelButton"
                  )}{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default LoginPageWithRedirect;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import components
import Products from "./products";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneDescription from "./PhoneDescription";
import { translateComponent } from "../../utils/services/translate";
import getCurrencyLabel from "../../utils/helper/currencyLabel";

// import actions
import {
  setBasketData,
  setFailedToSendOrder,
  setFriendlyNameNumber,
} from "../../redux/actions";

// import css & images
import paymentLogos from "../../assets/img/payment/paymentLogos.png";
import "react-phone-number-input/style.css";
import "./styles.scss";

// auth
import useAuth from "../../hooks/useAuth";

function BookingForm() {
  const { isAuthed, logout } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const handleToggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.Order.basket);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const project = useSelector((state) => state.Project.project);

  const handlePhoneInput = (phone) => {
    dispatch(setFailedToSendOrder(false));
    let updatedBasket = { ...basket, phoneValid: false, phone };

    if (phone !== undefined && phone.length > 3) {
      updatedBasket.phoneValid = isPossiblePhoneNumber(phone);
    }
    dispatch(setBasketData(updatedBasket));
  };

  const startPayment = async () => {
    try {
      dispatch(setFailedToSendOrder(false));
      var phoneNumber = "";
      if (basket.basketValid && isAuthed()) {
        phoneNumber = basket.phone;
      } else {
        var phoneInputElement = document.querySelector(".PhoneInputInput");
        phoneNumber = phoneInputElement.value;
      }

      dispatch(setFriendlyNameNumber(phoneNumber));
      navigate("/paymentcheck");
    } catch (error) {
      console.error("Error while sending order:", error);
      dispatch(setFailedToSendOrder(true));
    }
  };

  useEffect(() => {
    if (isAuthed()) {
      let phone = localStorage.getItem("phoneNumber");
      handlePhoneInput(phone);
    }
  }, []);

  if (basket !== null && basket.basketValid) {
    let formLang = "SE";
    if (language === "fi-FI") {
      formLang = "FI";
    }
    return (
      <>
        <div
          id="bookingForm"
          className="bookingForm p-3 rounded bg-light p-2 pt-1"
        >
          <div className="row">
            <div className="col-12 text-center ">
              <h2 className="pb-4 pt-2 buy-ticket-header">
                {" "}
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "buyTicketText"
                )}
              </h2>
            </div>
          </div>
          <Products />

          <div className="text-end pe-2 pb-2 mt-3">
            <span
              id={"component_bookingForm_total"}
              className="text-extra-bold"
            >
              {translateComponent(
                translations,
                language,
                "bookingForm",
                "total"
              )}
              :
            </span>{" "}
            <span className="text-l text-center">
              {basket.total} {getCurrencyLabel(language, project.currency)}
            </span>
            <p className="text-m mb-0">
              {translateComponent(translations, language, "bookingForm", "tax")}
            </p>
          </div>
          <p
            id={"component_bookingForm_phoneNumber"}
            className="pt-3 pb-3 text-extra-bold mb-1 d-inline"
          >
            {translateComponent(
              translations,
              language,
              "bookingForm",
              "phoneNumber"
            )}
          </p>
          <span
            className="btn-info ms-2 pt-3"
            onClick={() => handleToggleDialog()}
          ></span>
          <PhoneDescription show={showDialog}>
            <>
              <p>
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "PhoneDescriptionLine1"
                )}
              </p>

              <p>
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "PhoneDescriptionLine2"
                )}
              </p>
              <ul>
                <li>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "bookingForm",
                    "PhoneDescriptionLine3"
                  )}
                </li>
                <li>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "bookingForm",
                    "PhoneDescriptionLine4"
                  )}
                </li>
                <li>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "bookingForm",
                    "PhoneDescriptionLine5"
                  )}
                </li>
              </ul>

              <p>
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "PhoneDescriptionLine6"
                )}
              </p>

              <p>
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "PhoneDescriptionLine7"
                )}
              </p>
            </>

            <div className="text-center">
              <div className="pt-1 pb-2 border-green border-b h-50"></div>

              <button
                className="btn btn-link text-center text-m m-0 p-0 w-100 pt-2"
                onClick={() => handleToggleDialog()}
              >
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "closeTextButton"
                )}
              </button>
            </div>
          </PhoneDescription>
          {isAuthed() ? (
            <div className=" bg-white w-100 rounded p-1">
              <p className="p-3 ps-3 m-0 d-inline"> {basket.phone} </p>
            </div>
          ) : (
            <PhoneInput
              type="tel"
              placeholder="phone"
              className="is-invalid pb-2 pt-2"
              defaultCountry={formLang}
              international
              value={basket.phone}
              onChange={handlePhoneInput}
              maxLength={20}
            />
          )}

          <p
            id={"component_bookingForm_phoneNumber_info"}
            className="text-m text-center mb-3"
          >
            {translateComponent(
              translations,
              language,
              "bookingForm",
              "phoneNumber_info"
            )}
          </p>
          {!basket.phoneValid &&
            basket.phone !== undefined &&
            basket.phone.length > 6 && (
              <p
                id={"component_bookingForm_error_phone_number_validation"}
                className="text-m mb-0 mb-3 bg-white p-2"
              >
                <span className="text-danger">*</span>
                {translateComponent(
                  translations,
                  language,
                  "bookingForm",
                  "error_phone_number_validation"
                )}
              </p>
            )}
          <button
            id={"component_bookingForm_button_pay"}
            type="submit"
            onClick={startPayment}
            className="btn btn-success p-3 w-100 text-l"
            disabled={!basket.phoneValid}
          >
            {translateComponent(
              translations,
              language,
              "bookingForm",
              "button_pay"
            )}
          </button>
          {isAuthed() ? (
            <button
              id={"component_bookingForm_button_pay"}
              type="submit"
              onClick={() => {
                logout();
              }}
              className="btn btn-secondary p-3 w-100 text-l mt-2"
              disabled={!basket.phoneValid}
            >
              {translateComponent(
                translations,
                language,
                "bookingForm",
                "logout"
              )}
            </button>
          ) : null}
        </div>
        <div className="paymentOptions pt-4">
          <img
            src={paymentLogos}
            alt="payment options"
            className="mr-4 img-fluid"
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div
        id="bookingForm"
        className="bookingForm p-3 rounded bg-light p-2 pt-1"
      >
        <div className="row">
          <div className="col-12 text-center ">
            <h2 className="pb-4 pt-2 buy-ticket-header">
              {" "}
              {translateComponent(
                translations,
                language,
                "bookingForm",
                "buyTicketText"
              )}
            </h2>
          </div>
        </div>

        <Products></Products>
      </div>
      <div className="paymentOptions pt-4">
        <img
          src={paymentLogos}
          alt="payment options"
          className="mr-4 img-fluid"
        />
      </div>
    </>
  );
}

export default BookingForm;

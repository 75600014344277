import React from "react";

function PhoneDescription({ show, onClose, children }) {
  if (!show) return null;

  return (
    <div className="product-container bg-white rounded p-2 mb-5 mt-1 pb-3">
      <div className="phonenumber-dialog-content">{children}</div>
    </div>
  );
}

export default PhoneDescription;

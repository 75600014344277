import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTranslations } from "./redux/actions";
import { setLanguage } from "./redux/actions";
import { getPreferedLanguage } from "./utils/localStorage";

//Navigation
import Default from "./components/navigation/Default";
import Footer from "./components/navigation/Footer";

//Pages
import LandingPageByProject from "./pages/landing/landingPageByProject";
import LandingPage from "./pages/landing/landingPage";
import NotFound from "./pages/NotFoundPage";
import PaymentSelectionPage from "./pages/payment/paymentSelectionPage";
import SwishCheckoutPage from "./pages/payment/swishCheckoutPage";
import NetsCheckoutPage from "./pages/payment/netsCheckoutPage";
import ReceiptPage from "./pages/receipt/recieptPage";
import LoginPage from "./pages/login/LoginPage";
import PublicOrderPage from "./pages/order/publicOrderPage";
import EmptyPage from "./pages/EmptyPage";

// private pages
import OrdersPage from "./pages/home/private/order/ordersPage";
import OrderPage from "./pages/home/private/order/orderPage";
import TicketRefundPage from "./pages/home/private/ticket/ticketRefundPage";
import TicketsPage from "./pages/home/private/ticket/ticketsPage";
import TicketPage from "./pages/home/private/ticket/ticketPage";
import LoginPageWithRedirect from "./pages/login/LoginWithRedirect";
import CookiePage from "./pages/CookiePage";
import SwishCallbackPage from "./pages/payment/swishCallbackPage";

function RequireAuth({ children }) {
  const { isAuthed } = useAuth();
  const location = useLocation();
  return isAuthed() === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch translations file
    const fetchTranslations = async () => {
      try {
        dispatch(setLanguage(getPreferedLanguage()));
      } catch {
        console.log("No language set");
      }

      try {
        const response = await fetch("/lang/translation.json");
        const data = await response.json();
        dispatch(setTranslations(data));
      } catch (error) {
        console.error("Error fetching translations:", error);
      }
    };

    fetchTranslations();
  }, [dispatch]);
  return (
    <div>
      <Default />
      <Routes>
        <Route path="/" element={<EmptyPage />} />
        <Route path="/paymentcheck" element={<PaymentSelectionPage />} />

        <Route
          path="/payment/swish/:id"
          element={
            <RequireAuth>
              <SwishCheckoutPage />{" "}
            </RequireAuth>
          }
        />
        <Route
          path="/payment/swish/cb/:id"
          element={
            <RequireAuth>
              <SwishCallbackPage />{" "}
            </RequireAuth>
          }
        />
        <Route path="/cookies" element={<CookiePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/paymentcheck/login" element={<LoginPageWithRedirect />} />
        <Route
          path="/home/orders"
          element={
            <RequireAuth>
              <OrdersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/orders/:referenceId"
          element={
            <RequireAuth>
              <OrderPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/ticket/refund/order/:referenceId/ticket/:ticketId"
          element={
            <RequireAuth>
              <TicketRefundPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/tickets"
          element={
            <RequireAuth>
              <TicketsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/order/:orderReferenceId/ticket/:ticketId"
          element={
            <RequireAuth>
              <TicketPage />
            </RequireAuth>
          }
        />
        <Route
          path="/payment/nets/:paymentId/:referenceId"
          element={
            <RequireAuth>
              <NetsCheckoutPage />
            </RequireAuth>
          }
        />
        <Route
          path="/t/:referenceId"
          element={
            <RequireAuth>
              <ReceiptPage />
            </RequireAuth>
          }
        />
        <Route
          path="/order/:referenceId"
          element={
            <RequireAuth>
              <PublicOrderPage />
            </RequireAuth>
          }
        />
        <Route path="/:project" element={<LandingPageByProject />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

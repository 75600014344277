import moment from "moment";
import { translateComponent } from "../../../utils/services/translate";
import { Link } from "react-router-dom";

function getStatusMessage(translations, language, ticketStatus) {
  const statusMessages = {
    valid: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusValid"
      ),
      className: "text-success text-secondary text-m text-extra-bold",
    },
    activated: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusActivated"
      ),
      className: "text-success text-m text-extra-bold",
    },
    used: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusUsed"
      ),
      className: "text-danger text-m text-extra-bold",
    },
    refunded: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusRefunded"
      ),
      className: "text-danger text-m text-extra-bold",
    },
    expired: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusExpired"
      ),
      className: "text-danger text-m text-extra-bold",
    },
    pendingRefund: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusPendingRefund"
      ),
      className: "text-warning text-m text-extra-bold",
    },
    refundFailed: {
      text: translateComponent(
        translations,
        language,
        "ticketDetailsComponent",
        "ticketStatusRefundFailed"
      ),
      className: "text-danger text-m text-extra-bold",
    },
  };
  const status = statusMessages[ticketStatus];
  if (!status) return null; // Returnera null om statusen inte finns i objektet

  return {
    text: status.text,
    className: status.className,
  };
}

const formatDate = (date) => {
  try {
    return moment(date).format("YYYY-MM-DD HH:mm");
  } catch {
    return "";
  }
};

const getProductPrice = (item, language) => {
  let currency = "SEK";
  if (language === "sv") {
    currency = "kr";
  }
  var i = item.price + " " + currency;
  if (item.status !== "refunded") {
    return <span className="text-m">{i}</span>;
  } else {
    let refundedItem = "-" + item.price + " " + currency;
    return (
      <span className="text-m text-extra-bold text-danger">{refundedItem}</span>
    );
  }
};

const isRefundable = (
  orderReferenceId,
  orderItem,
  ticket,
  language,
  translations,
  publicOrder
) => {
  if (ticket === null) {
    return null;
  }
  if (ticket.refundAllowed && orderItem.status === "paid") {
    if (publicOrder === undefined || publicOrder) {
      return (
        <>
          <div className="bg-info2 p-1 mt-2">
            <div className="bg-white p-2">
              <strong>
                {translateComponent(
                  translations,
                  language,
                  "ticketDetailsComponent",
                  "ticketRefundableh1"
                )}
                :
              </strong>{" "}
              <p className=" text-m ">
                {translateComponent(
                  translations,
                  language,
                  "ticketDetailsComponent",
                  "ticketRefundableText"
                )}
              </p>
            </div>
          </div>
        </>
      );
    } else {
      let refundUrl =
        "/home/ticket/refund/order/" +
        orderReferenceId +
        "/ticket/" +
        ticket.referenceId;

      return (
        <>
          <div className="bg-info2 p-1 mt-2">
            <div className="bg-white p-2">
              <strong>
                {" "}
                {translateComponent(
                  translations,
                  language,
                  "ticketDetailsComponent",
                  "ticketRefundableh1"
                )}
                :
              </strong>{" "}
              <p className="text-m">
                {translateComponent(
                  translations,
                  language,
                  "ticketDetailsComponent",
                  "ticketRefundableText2"
                )}
              </p>
              <Link className="btn btn-danger w-100" to={refundUrl}>
                {translateComponent(
                  translations,
                  language,
                  "ticketDetailsComponent",
                  "ticketRefundableButtonText"
                )}
              </Link>
            </div>
          </div>
        </>
      );
    }
  } else {
    return null;
  }
};

const showExpirationDate = (orderItem, ticket, language, translations) => {
  if (ticket === null) {
    return null;
  }
  if (ticket.status === "valid" && orderItem.status === "paid") {
    return (
      <>
        <strong>
          {translateComponent(
            translations,
            language,
            "ticketDetailsComponent",
            "validUntil"
          )}
          :
        </strong>{" "}
        {formatDate(ticket.expirationDate)}
      </>
    );
  } else {
    return null;
  }
};

const findTicketByReferenceId = (order, referenceId) => {
  try {
    return order.tickets.find((ticket) => ticket.referenceId === referenceId);
  } catch {
    return null;
  }
};
const ticketStatus = (orderItem, ticketStatus, language, translations) => {
  if (orderItem.status === "paid" || orderItem.status === "refunded") {
    const status = getStatusMessage(translations, language, ticketStatus);
    return (
      <>
        <strong>
          {translateComponent(
            translations,
            language,
            "ticketDetailsComponent",
            "ticketStatusText"
          )}
          :
        </strong>{" "}
        <span className={status.className}>{status.text}</span>
      </>
    );
  } else {
    return null;
  }
};

const TicketDetailsComponent = (
  item,
  order,
  language,
  translations,
  publicOrder
) => {
  const ticket = findTicketByReferenceId(order, item.referenceId, publicOrder);

  if (ticket === null) {
    return null;
  }
  if (item.productType !== "merchandise") {
    return (
      <div className="pb-3">
        <p className="text-m bg-light text-center">{item.referenceId}</p>
        {item.status === "refunded" && (
          <div>
            <strong>
              {translateComponent(
                translations,
                language,
                "ticketDetailsComponent",
                "refunded"
              )}
              :
            </strong>{" "}
            {formatDate(item.paidDateTime)}
          </div>
        )}
        {item.status === "pendingRefund" && (
          <div>
            <strong>
              {translateComponent(
                translations,
                language,
                "ticketDetailsComponent",
                "pendingRefund"
              )}
              :
            </strong>{" "}
            {formatDate(item.paidDateTime)}
          </div>
        )}
        {item.status === "refundFailed" && (
          <div>
            <strong>
              {translateComponent(
                translations,
                language,
                "ticketDetailsComponent",
                "refunded"
              )}
              :
            </strong>{" "}
            {formatDate(item.paidDateTime)}
          </div>
        )}
        {ticket.status === "valid" || ticket.status === "activated" ? (
          <div>
            <Link
              className="btn btn-secondary w-100 mb-3"
              to={`/home/order/${order.referenceId}/ticket/${ticket.referenceId}`}
            >
              {translateComponent(
                translations,
                language,
                "ticketDetailsComponent",
                "showTicket"
              )}
            </Link>
          </div>
        ) : null}

        <div>
          <strong>
            {translateComponent(
              translations,
              language,
              "ticketDetailsComponent",
              "price"
            )}
            :
          </strong>{" "}
          {getProductPrice(item, language)}
        </div>

        <div>{ticketStatus(item, ticket?.status, language, translations)}</div>
        <div>{showExpirationDate(item, ticket, language, translations)}</div>
        {isRefundable(
          order.referenceId,
          item,
          ticket,
          language,
          translations,
          publicOrder
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default TicketDetailsComponent;

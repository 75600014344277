import React from "react";

const NotFound = () => {
  return (
    <div className="container min-height-100">
      <div className="row">
        <h1 className="mt-5 pt-5 text-center ">Error: NotFound</h1>
        <p className="text-center">Sidan kunde inte hittas.</p>
      </div>
    </div>
  );
};

export default NotFound;

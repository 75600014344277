const ticketApiBaseUri = process.env.REACT_APP_PUBLIC_API_BASE_URI;
export const TicketApi = {
  GET_PRODUCTS_URL: ticketApiBaseUri + "/api/products",
  GET_PROJECTS_URL: ticketApiBaseUri + "/api/projects",
  GET_PROJECTS_FOR_AUTHED_USER: ticketApiBaseUri + "/api/projects",
  GET_CATEGORIES_URL: ticketApiBaseUri + "/api/categories",
  POST_ORDERS: ticketApiBaseUri + "/api/orders",
  GET_ORDER: ticketApiBaseUri + "/api/orders",
  GET_ORDERS: ticketApiBaseUri + "/api/orders",
  POST_TICKET_ACTIVATE: ticketApiBaseUri + "/api/tickets/activate",
  POST_TICKET_FRAUD_PROTECTION: ticketApiBaseUri + "/api/tickets/fraud",
  GET_TICKET_REFUND_STATUS: ticketApiBaseUri + "/api/tickets/refund",
  GET_TICKET: ticketApiBaseUri + "/api/tickets/reference",
  GET_TICKETS: ticketApiBaseUri + "/api/tickets",
  POST_TICKET_REFUND: ticketApiBaseUri + "/api/tickets/refund",
  POST_IDENTITY_LOGIN: ticketApiBaseUri + "/api/identity/login",
  POST_IDENTITY_PASSCODE: ticketApiBaseUri + "/api/identity/login/passcode",
  POST_IDENTITY_AUTHORIZED: ticketApiBaseUri + "/api/identity/authorized",
  POST_PAYMENT_REQUEST: ticketApiBaseUri + "/api/payments/request",
  POST_SWISH_QR_CODE: ticketApiBaseUri + "/api/payments/swish/generate/qr",
};

import { TicketApi } from "./constants";
import { getAsyncRetry3, postAsync } from "./restHelper";

import {
  protectedApiRequest,
  protectedApiRequestV2,
} from "./protected/protectedClientHelper";
import protectedClient from "./protected/protectedClient";
// products

// ticket
export async function activateTicket(referenceId) {
  let bodyObject = {
    referenceId: referenceId,
  };

  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_TICKET_ACTIVATE, bodyObject)
  );
}
export async function fraudProtection(referenceId, token) {
  let bodyObject = {
    referenceId: referenceId,
    token: token,
  };
  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_TICKET_FRAUD_PROTECTION, bodyObject)
  );
}

export async function PostTicketRefund(orderReferenceId, ticketReferenceId) {
  let bodyObject = {
    TicketReferenceNumber: ticketReferenceId,
    OrderReferenceNumber: orderReferenceId,
  };
  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_TICKET_REFUND, bodyObject)
  );
}

export async function getTicketRefundStatus(referenceId) {
  return protectedApiRequest(() =>
    protectedClient.get(
      TicketApi.GET_TICKET_REFUND_STATUS + "/" + referenceId + "/refund/status"
    )
  );
}
export async function getTickets() {
  return protectedApiRequest(() => protectedClient.get(TicketApi.GET_TICKETS));
}
export async function getTicket(referenceId) {
  return protectedApiRequest(() =>
    protectedClient.get(TicketApi.GET_TICKET + "/" + referenceId)
  );
}
// projects
export async function getProjects() {
  return await getAsyncRetry3(TicketApi.GET_PROJECTS_URL);
}

export async function getProjectsBySlug(slug) {
  return await getAsyncRetry3(
    TicketApi.GET_PROJECTS_URL + "/slug?slug=" + slug
  );
}

// tags
export async function getCategories() {
  return await getAsyncRetry3(TicketApi.GET_CATEGORIES_URL);
}

export async function getProductsBySlug(slug) {
  return await getAsyncRetry3(
    TicketApi.GET_PRODUCTS_URL + "/slug?slug=" + slug
  );
}
// Order
export async function createOrder(phoneNumber, basket, projectId) {
  let items = [];
  basket.products.forEach((product) => {
    if (product.type === "reservedSeatTicket") {
      items.push({
        productId: product.id,
        quantity: product.quantity,
        departureId: product.departure.id,
      });
    } else {
      items.push({
        productId: product.id,
        quantity: product.quantity,
      });
    }
  });
  let bodyObject = {
    phoneNumber: phoneNumber,
    items: items,
    projectId: projectId,
  };
  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_ORDERS, bodyObject)
  );
}
export async function getProtectedOrder(referenceId) {
  return protectedApiRequest(() =>
    protectedClient.get(TicketApi.GET_ORDER + "/Reference/" + referenceId)
  );
}

export async function getOrders() {
  return protectedApiRequest(() => protectedClient.get(TicketApi.GET_ORDERS));
}

// PAYMENT
export async function startSwishPayment(referenceId) {
  let bodyObject = {
    referenceId: referenceId,
    paymentProvider: "swish",
    netsConfiguration: {
      paymentMethods: ["swish"],
      currency: "SEK",
    },
  };
  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_PAYMENT_REQUEST, bodyObject)
  );
}

export async function identityAuthorized() {
  var phone = localStorage.getItem("phoneNumber");

  if (phone === undefined || phone === null) {
    return undefined;
  } else {
    return protectedApiRequestV2(() =>
      protectedClient.get(TicketApi.POST_IDENTITY_AUTHORIZED)
    );
  }
}

export async function startNetsPayment(referenceId, currency, paymentMethods) {
  let bodyObject = {
    referenceId: referenceId,
    paymentProvider: "nets",
    netsConfiguration: {
      paymentMethods: paymentMethods,
      currency: currency,
    },
  };
  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_PAYMENT_REQUEST, bodyObject)
  );
}
export async function getSwishQrCodeImage(token) {
  let bodyObject = {
    token: token,
    format: "svg",
    size: "300",
    border: 0,
    transparet: true,
  };

  return protectedApiRequest(() =>
    protectedClient.post(TicketApi.POST_SWISH_QR_CODE, bodyObject)
  );
}

// Identity
export async function postPasscode(phoneNumber, verificationCode) {
  let bodyObject = {
    phoneNumber: phoneNumber,
    verificationCode: verificationCode,
  };
  return postAsync(TicketApi.POST_IDENTITY_PASSCODE, bodyObject);
}
export async function postLogin(phoneNumber) {
  let bodyObject = {
    phoneNumber: phoneNumber,
  };
  return postAsync(TicketApi.POST_IDENTITY_LOGIN, bodyObject);
}

// @flow
import { ProductActionTypes } from "./constants";

type ProductAction = { type: string, payload?: string | null };

export const setProductList = (data): ProductAction => ({
  type: ProductActionTypes.SET_PRODUCT_LIST,
  payload: data,
});

export const setSelectedProduct = (data): ProductAction => ({
  type: ProductActionTypes.SET_SELECTED_PRODUCT,
  payload: data,
});
export const filterSelectedProductsByCategory = (name): ProductAction => ({
  type: ProductActionTypes.SET_SELECTED_PRODUCT_BY_CATEGORY,
  payload: name,
});

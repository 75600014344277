export async function getAsync(endpoint) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "GET",
    headers: headers,
  };
  return fetch(endpoint, options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
}

export async function postAsync(endpoint, bodyObject) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(bodyObject),
  };

  return fetch(endpoint, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response;
    })
    .catch((error) => console.log(error));
}

export async function getAsyncRetry3(url) {
  return new Promise((resolve, reject) => {
    let timeout = 3; // timeout interval in seconds
    let retries = 0;
    let maxRetries = 3; // maximum number of retries

    function call() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const options = {
        method: "GET",
        headers: headers,
      };

      fetch(url, options)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.json());
          } else {
            if (retries < maxRetries) {
              setTimeout(() => {
                retries++;
                call();
              }, timeout * 1000);
            } else {
              resolve(response.json());
            }
          }
        })
        .catch((error) => {
          if (retries < maxRetries) {
            setTimeout(() => {
              retries++;
              call();
            }, timeout * 1000);
          } else {
            resolve(undefined);
          }
        });
    }

    call();
  });
}

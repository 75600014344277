import React, { useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useDispatch } from "react-redux";

// redux
import { setSwishPaymentResponseError } from "../../redux/actions";

const SwishCheckoutSignalR = ({ orderReference }) => {
  const [accessToken, setAccessToken] = useState("");
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  const [hubConnection, setHubConnection] = useState(null);

  useEffect(() => {
    async function getAccessToken() {
      try {
        const response = await fetch(
          process.env.REACT_APP_SIGNALR_BASE_URI + "/api/Negotiate"
        );
        const data = await response.json();
        setAccessToken(data.accessToken);
        setUrl(data.url);
      } catch (e) {
        console.log("failed to open socket");
      }
    }

    getAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      const hubConnect = new HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .build();

      hubConnect.start().then(() => {
        hubConnect.on(orderReference, (response) => {
          var message = JSON.parse(response);
          dispatch(
            setSwishPaymentResponseError(
              message.orderReference,
              message.status,
              message.errorMessage,
              message.errorCode
            )
          );
        });
      });

      hubConnect.onreconnected((connectionId) => {
        console.log(`Connected with connectionId ${connectionId}`);
      });

      setHubConnection(hubConnect);

      return () => {
        if (hubConnection) {
          hubConnection.stop();
        }
      };
    }
    // eslint-disable-next-line
  }, [accessToken, dispatch, orderReference, url]);

  return <div></div>;
};

export default SwishCheckoutSignalR;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

// components
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
// Api
import { postLogin } from "../../utils/api/restClient";

// redux actions
import {
  setSmsSent,
  setPhoneNumber,
  setPhoneChar,
  setPhoneValid,
  setSmsSentFailure,
  setVerificationCode,
  setVerificationCodeValid,
  setVerificationCodeLength,
  setDisableNavigation,
  setLoading,
} from "../../redux/actions";

// utils
import { translatePage } from "../../utils/services/translate";

// scss

import "./LoginPage.scss";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useAuth();

  const {
    sms_sent,
    phone_valid,
    phone_number,
    loading,
    verification_code_length,
    verification_code,
    verification_code_valid,
    verification_code_not_accepted,
  } = useSelector((state) => state.Login);

  const { language, translations } = useSelector((state) => state.Layout);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setDisableNavigation(true));
  }, [dispatch]);

  function sendSMS(event, dispatch, phoneNumber) {
    event.preventDefault();

    dispatch(setSmsSentFailure(false));
    dispatch(setSmsSent(false));
    dispatch(setLoading(true));
    postLogin(phoneNumber).then((res) => {
      dispatch(setLoading(false));
      if (res !== undefined && res.smsSent) {
        dispatch(setSmsSent(true));
      } else {
        dispatch(setPhoneValid(false));
        dispatch(setSmsSentFailure(true));
      }
    });
  }

  function handlePhoneInput(dispatch, phoneNumber) {
    dispatch(setSmsSentFailure(false));
    if (phoneNumber !== undefined && phoneNumber.length > 5) {
      dispatch(setPhoneValid(isPossiblePhoneNumber(phoneNumber)));
    }
    if (phoneNumber !== undefined && phoneNumber.length > 3) {
      dispatch(setPhoneChar(phoneNumber.length));
      dispatch(setPhoneNumber(phoneNumber));
    }
  }

  function handleCodeInput(event, current_code_length, dispatch) {
    var codeLength = event.target.value.length;
    dispatch(setVerificationCodeLength(codeLength));

    if (codeLength <= 6) {
      dispatch(setVerificationCode(event.target.value));
    }
    if (codeLength < 6) {
      dispatch(setVerificationCodeValid(false));
    } else {
      dispatch(setVerificationCodeValid(true));
    }
  }

  function GoToStartPage(navigate, dispatch) {
    dispatch(setDisableNavigation(false));
    navigate("/");
  }

  // Stage 1
  if (!sms_sent && !loading) {
    let formLang = "SE";
    if (language === "fi-FI") {
      formLang = "FI";
    }

    return (
      <>
        <div className="login-container">
          <form className="login-form">
            <div className="bg-light p-4">
              <h1 className="text-center">
                {translatePage(translations, language, "loginPage", "login")}
              </h1>
            </div>
            <div className="login-form-content pt-2">
              <a
                href="/"
                onClick={() => {
                  GoToStartPage(navigate, dispatch);
                }}
              >
                {translatePage(
                  translations,
                  language,
                  "loginPage",
                  "goToStartPage"
                )}
              </a>
              <div className="form-group mt-3">
                <p className="text-m">
                  {translatePage(
                    translations,
                    language,
                    "loginPage",
                    "smsTextToPhone"
                  )}
                </p>
                <PhoneInput
                  placeholder="Mobiltelefon"
                  className="border p-1 mt-3"
                  defaultCountry={formLang}
                  international
                  value={phone_number}
                  onChange={(phoneNumber) =>
                    handlePhoneInput(dispatch, phoneNumber)
                  }
                  maxLength={20}
                />
              </div>
              {!phone_valid && phone_number.length > 5 ? (
                <div className="d-grid gap-2 text-danger text-center bg-light">
                  <p className="text-m pt-3">
                    {translatePage(
                      translations,
                      language,
                      "loginPage",
                      "checkPhoneNumber"
                    )}
                  </p>
                </div>
              ) : null}
              {phone_valid ? (
                <div className="d-grid gap-2 mt-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={(event) => sendSMS(event, dispatch, phone_number)}
                  >
                    {translatePage(
                      translations,
                      language,
                      "loginPage",
                      "sendSmsButton"
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </>
    );
  }
  // stage 2
  if (sms_sent) {
    return (
      <div className="login-container">
        <form className="login-form">
          <div className="bg-light">
            <h3 className="text-center p-3">
              {translatePage(translations, language, "loginPage", "codeTitle")}
            </h3>
          </div>
          <div className="login-form-content">
            <p className="text-m text-center">
              {translatePage(
                translations,
                language,
                "loginPage",
                "codeDescription"
              )}{" "}
              {phone_number}
            </p>
            <div className="form-group ">
              <input
                type="number"
                className="form-control mt-1"
                placeholder="xxx xxx"
                value={verification_code}
                onChange={(event) =>
                  handleCodeInput(event, verification_code_length, dispatch)
                }
              />
            </div>
            {verification_code_not_accepted ? (
              <div className="alert alert-danger text-center mt-2">
                {translatePage(
                  translations,
                  language,
                  "loginPage",
                  "errorWrongCode"
                )}{" "}
              </div>
            ) : null}
            {verification_code_valid ? (
              <div className="d-grid gap-2 mt-3 mb-0">
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={(event) => login(event)}
                >
                  {translatePage(
                    translations,
                    language,
                    "loginPage",
                    "verifyButton"
                  )}{" "}
                </button>
              </div>
            ) : (
              <div className="d-grid gap-2 mt-3 mb-0">
                <button type="submit" className="btn btn-success" disabled>
                  {translatePage(
                    translations,
                    language,
                    "loginPage",
                    "verifyButton"
                  )}{" "}
                </button>
              </div>
            )}
            <div className="d-grid gap-2 mt-3">
              <button className="btn btn-outline-secondary mt-4">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "loginPage",
                  "cancelButton"
                )}{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
  if (loading) {
    return <>{LoadingComponent("Laddar..", loading)}</>;
  }
}

export default LoginPage;

import React, { useState, useEffect } from "react";
import { getSwishQrCodeImage } from "../../../utils/api/restClient";
import { useSelector } from "react-redux";
import { translateComponent } from "../../../utils/services/translate";

function QrCodeDialog({ show, handleClose, token }) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const [svgContent, setSvgContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show && token) {
      fetchSvg(token);
    }
  }, [show, token]);

  const fetchSvg = async (token) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getSwishQrCodeImage(token);
      if (response.error || response.status) {
        throw new Error("Something bad happend when getting QR-Code.");
      }
      setSvgContent(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className={`modal rounded ${show ? "d-block" : "d-none"}`}
      tabIndex="-1"
    >
      <div className="modal-dialog rounded">
        <div className="modal-content rounded">
          <div className="text-center border-bottom p-3 bg-light rounded">
            <h3 className="text-center">
              {" "}
              {translateComponent(
                translations,
                language,
                "qrCodeDialog",
                "h3Text"
              )}
            </h3>
          </div>
          <div className="modal-body">
            {isLoading && <></>}
            {error && <p className="text-danger">{error}</p>}
            {svgContent && (
              <div dangerouslySetInnerHTML={{ __html: svgContent }} />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleClose}
            >
              {translateComponent(
                translations,
                language,
                "qrCodeDialog",
                "closeButtonText"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodeDialog;

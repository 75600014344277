import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.scss";
const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Provider store={configureStore({})}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </Router>
);

// @flow
import { PaymentActionTypes } from "./constants";

type PaymentAction = { type: string, payload?: string | null };

export const setPaymentResultError = (data): PaymentAction => ({
  type: PaymentActionTypes.SET_PAYMENT_ERROR_RESULT,
  payload: data,
});

export const setPaymentError = (data): PaymentAction => ({
  type: PaymentActionTypes.SET_PAYMENT_ERROR,
  payload: data,
});
export const setSelectedPaymentOption = (data): PaymentAction => ({
  type: PaymentActionTypes.SET_SELECTED_PAYMENT_OPTION,
  payload: data,
});

export const setSwishPaymentResponseError = (
  orderReference,
  status,
  errorMessage,
  errorCode
): PaymentAction => ({
  type: PaymentActionTypes.SET_SWISH_PAYMENT_RESPONSE_STATUS,
  payload: {
    orderReference,
    status,
    errorMessage,
    errorCode,
  },
});

import React from "react";

const ShowEnvironmentComponent = () => {
  let env = process.env.REACT_APP_ENVIRONMENT;
  if (env === "TEST") {
    return <div className="text-danger text-extra-bold">TEST ENV</div>;
  }
  return null;
};

export default ShowEnvironmentComponent;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Translation
import { translatePage } from "../../utils/services/translate";

// Images
import swishAltImg from "../../assets/icons/payment/Swish_Logo_Primary.svg";
import cardAltImg from "../../assets/icons/payment/Nobina_payment_card 1.svg";
import applepayAltImg from "../../assets/icons/payment/applepay.svg";

// Styles
import "./paymentSelectionPage.scss";

// API
import { createOrder } from "../../utils/api/restClient";
import { startNetsPayment } from "../../utils/api/restClient";
import { identityAuthorized } from "../../utils/api/restClient";

// Components
import BasketSummary from "../../components/payment/basket/basketSummaryComponent";

// redux
import { setLoading, setSelectedPaymentOption } from "../../redux/actions";

// helper
import getCurrencyLabel from "../../utils/helper/currencyLabel";
// auth
import useAuth from "../../hooks/useAuth";

function PaymentSelectionPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    // TODO: Refactor this useEffect to a custom hook
    setLoading(true);
    identityAuthorized().then((res) => {
      setLoading(false);
      if (res === undefined || !res.authorized) {
        setUnauthorized(true);
      }
    });
  });

  const { isAuthed } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.Order.basket);
  const project_slug = useSelector((state) => state.Project.project_slug);
  const project = useSelector((state) => state.Project.project);
  const translations = useSelector((state) => state.Layout.translations);
  const language = useSelector((state) => state.Layout.language);
  const selectedOption = useSelector(
    (state) => state.Payment.selected_payment_option
  );

  const [orderReferenceId, setOrderReferenceId] = useState(null);
  const [apiNetsError, setNetsApiError] = useState(false);
  const [apiCreateOrderError, setCreateOrderApiError] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const options = {
    swish: "swish",
    visa: "card",
    mastercard: "card",
    applepay: "applepay",
  };

  let availableOptions = null;
  let colSize = "col-12";

  if (project !== null) {
    availableOptions = Object.keys(options).reduce((acc, key) => {
      const option = options[key];
      if (project.paymentOptions.includes(key) && !acc.includes(option)) {
        acc.push(option);
      }
      return acc;
    }, []);
  }

  const handleOptionClick = (option) => {
    dispatch(setSelectedPaymentOption(option));
  };

  // const getBasketTotalWithCurrency = (basket, lang) => {
  //   if (lang === "sv") {
  //     return "Betala " + basket.total + " kr";
  //   } else {
  //     return "Pay " + basket.total + " SEK";
  //   }
  // };

  const payDivRef = useRef(null);

  const handleProceed = () => {
    setIsProcessing(true);
    if (orderReferenceId === null) {
      setLoading(true);
      createOrder(basket.phone, basket, project.id).then((res) => {
        if (res === undefined || res.referenceId === undefined) {
          setCreateOrderApiError(true);
        } else {
          setOrderReferenceId(res.referenceId);
          if (selectedOption === "swish") {
            // swish
            navigate("/payment/swish/" + res.referenceId);
          } else {
            // nets
            dispatch(setLoading(true));
            if (selectedOption === "applepay") {
              startNetsPayment(res.referenceId, project.currency, [
                "applepay",
              ]).then((response) => {
                try {
                  if (response.netsIntegrationResponse.paymentId) {
                    navigate(
                      "/payment/nets/" +
                        response.netsIntegrationResponse.paymentId +
                        "/" +
                        res.referenceId
                    );
                  } else {
                    // Error
                    console.log("error");
                    setNetsApiError(true);
                  }
                } catch (error) {
                  setNetsApiError(true);
                } finally {
                  dispatch(setLoading(false));
                  setIsProcessing(false);
                }
              });
            } else {
              startNetsPayment(res.referenceId, project.currency, [
                "visa",
                "mastercard",
              ]).then((response) => {
                try {
                  if (response.netsIntegrationResponse.paymentId) {
                    navigate(
                      "/payment/nets/" +
                        response.netsIntegrationResponse.paymentId +
                        "/" +
                        res.referenceId
                    );
                  } else {
                    // Error
                    console.log("error");
                    setNetsApiError(true);
                  }
                } catch (error) {
                  setNetsApiError(true);
                } finally {
                  dispatch(setLoading(false));
                  setIsProcessing(false);
                }
              });
            }
          }
        }
      });
    } else {
      // handling retries and switching paymentMethod.
      if (selectedOption === "swish") {
        navigate("/payment/swish/" + orderReferenceId);
      } else {
        dispatch(setLoading(true));
        if (selectedOption === "applepay") {
          startNetsPayment(orderReferenceId, project.currency, [
            "applepay",
          ]).then((response) => {
            try {
              if (response.netsIntegrationResponse.paymentId) {
                navigate(
                  "/payment/nets/" +
                    response.netsIntegrationResponse.paymentId +
                    "/" +
                    orderReferenceId
                );
              } else {
                // Error
                console.log("error");
                setNetsApiError(true);
              }
            } catch (error) {
              setNetsApiError(true);
            } finally {
              dispatch(setLoading(false));
              setIsProcessing(false);
            }
          });
        }
        if (selectedOption === "card") {
          startNetsPayment(orderReferenceId, project.currency, [
            "visa",
            "mastercard",
          ]).then((response) => {
            try {
              if (response.netsIntegrationResponse.paymentId) {
                navigate(
                  "/payment/nets/" +
                    response.netsIntegrationResponse.paymentId +
                    "/" +
                    orderReferenceId
                );
              } else {
                // Error
                console.log("error");
                setNetsApiError(true);
              }
            } catch (error) {
              setNetsApiError(true);
            } finally {
              dispatch(setLoading(false));
              setIsProcessing(false);
            }
          });
        }
      }
    }
  };
  const retryPayment = () => {
    setNetsApiError(false);
  };
  const retryNetsPayment = (dispatch, orderReferenceId) => {
    setIsProcessing(true);
    setNetsApiError(false);
    dispatch(setLoading(true));
    startNetsPayment(orderReferenceId).then((response) => {
      if (selectedOption === "card") {
        startNetsPayment(orderReferenceId, project.currency, [
          "visa",
          "mastercard",
        ]).then((response) => {
          try {
            if (response.netsIntegrationResponse.paymentId) {
              navigate(
                "/payment/nets/" +
                  response.netsIntegrationResponse.paymentId +
                  "/" +
                  orderReferenceId
              );
            } else {
              // Error
              console.log("error");
              setNetsApiError(true);
            }
          } catch (error) {
            setNetsApiError(true);
          } finally {
            dispatch(setLoading(false));
            setIsProcessing(false);
          }
        });
      }
      if (selectedOption === "applepay") {
        startNetsPayment(orderReferenceId, project.currency, ["applepay"]).then(
          (response) => {
            try {
              if (response.netsIntegrationResponse.paymentId) {
                navigate(
                  "/payment/nets/" +
                    response.netsIntegrationResponse.paymentId +
                    "/" +
                    orderReferenceId
                );
              } else {
                // Error
                console.log("error");
                setNetsApiError(true);
              }
            } catch (error) {
              setNetsApiError(true);
            } finally {
              dispatch(setLoading(false));
              setIsProcessing(false);
            }
          }
        );
      }
    });
  };
  const retryCreateOrder = () => {
    setCreateOrderApiError(false);
  };
  const handleCancel = () => {
    navigate("/" + project_slug);
  };

  if (basket.total === 0 || !basket.basketValid || !basket.phoneValid) {
    if (project_slug != null) {
      navigate("/" + project_slug);
    }
    navigate("/");
  }
  // if (!isAuthed()) {
  //   navigate("/login");
  // }
  if (unauthorized) {
    navigate("/paymentcheck/login");
  }
  if (apiNetsError) {
    return (
      <div className="container min-height-100  d-flex justify-content-center align-items-center">
        <div className="row">
          {" "}
          <div className="swishErrorContainer ">
            <div className="col-12 text-center">
              <h1 className="mt-5 text-center">
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "technicalIssueh1"
                )}
              </h1>
              <p>
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "technicalIssueNetsError"
                )}
              </p>
            </div>
            <div className="row">
              <div className="col-12 pt-3 text-center">
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => retryPayment()}
                  disabled={isProcessing}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "changePaymentOption"
                  )}
                </button>
              </div>
              <div className="col-12 pt-3 text-center">
                <button
                  className="btn btn-success w-100"
                  onClick={() => retryNetsPayment(dispatch, orderReferenceId)}
                  disabled={isProcessing}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "tryAgainButton"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (apiCreateOrderError) {
    return (
      <div className="container min-height-100  d-flex justify-content-center align-items-center">
        <div className="error-container">
          <div className="row">
            <div className="col-12">
              <h1 className="mt-5">
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "technicalIssueh1"
                )}
              </h1>
              <p>
                {" "}
                {translatePage(
                  translations,
                  language,
                  "paymentSelectionPage",
                  "technicalIssueOrderError"
                )}
              </p>
            </div>
            <div className="row">
              <div className="col-12 pt-3 text-center">
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => retryCreateOrder()}
                  disabled={isProcessing}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "changePaymentOption"
                  )}
                </button>
              </div>
              <div className="col-12 pt-3 text-center">
                <button
                  className="btn btn-success w-100"
                  onClick={() => retryCreateOrder()}
                  disabled={isProcessing}
                >
                  {translatePage(
                    translations,
                    language,
                    "paymentSelectionPage",
                    "tryAgainButton"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (availableOptions !== null) {
    return (
      <>
        {isAuthed() && (
          <div
            ref={payDivRef}
            className="container min-height-100 d-flex justify-content-center mt-5 pt-5"
          >
            <div className="container-400 text-start">
              <div className="row">
                <div className="col-12 bg-light p-2 text-center mb-3">
                  <h3>
                    {translatePage(
                      translations,
                      language,
                      "paymentSelectionPage",
                      "yourOrderh1"
                    )}
                  </h3>
                </div>
              </div>
              <BasketSummary />
              {availableOptions.length > 1 && (
                <div className="row">
                  <div className="col-12 bg-light p-2 text-center mb-3">
                    <h3>
                      {translatePage(
                        translations,
                        language,
                        "paymentSelectionPage",
                        "selectPaymentMethod"
                      )}
                    </h3>
                  </div>
                </div>
              )}
              {availableOptions.length > 1 ? (
                <div className="row">
                  {availableOptions.map((option) => {
                    const isSelected = selectedOption === option;
                    const optionImg =
                      option === "swish"
                        ? swishAltImg
                        : option === "card"
                        ? cardAltImg
                        : applepayAltImg;

                    let optionText =
                      option === "swish"
                        ? "Swish"
                        : option === "card"
                        ? "Kortbetalning"
                        : "Apple Pay";
                    if (language === "en-US") {
                      optionText =
                        option === "swish"
                          ? "Swish"
                          : option === "card"
                          ? "Card payment"
                          : "Apple Pay";
                    }
                    if (language === "fi-FI") {
                      optionText =
                        option === "swish"
                          ? "Swish"
                          : option === "card"
                          ? "Korttimaksu"
                          : "Apple Pay";
                    }
                    return (
                      <div
                        key={option}
                        className={`${colSize} ${
                          isSelected ? "selected" : "grayed-out"
                        }`}
                        onClick={() => handleOptionClick(option)}
                      >
                        <div
                          className={`image-container d-flex align-items-center p-2 box-option ${
                            isSelected ? "selected" : ""
                          }`}
                        >
                          <img
                            src={optionImg}
                            alt={`${option} image`}
                            className="paymentIcon"
                          />
                          <p className="mt-2 text-extra-bold paymentText">
                            {optionText}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    className="btn btn-success w-100"
                    onClick={handleProceed}
                    disabled={isProcessing}
                  >
                    {translatePage(
                      translations,
                      language,
                      "paymentSelectionPage",
                      "payButtonText"
                    )}
                    &nbsp;
                    {basket.total}{" "}
                    {getCurrencyLabel(language, project.currency)}
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-secondary w-100 mt-3"
                    onClick={handleCancel}
                  >
                    {translatePage(
                      translations,
                      language,
                      "paymentSelectionPage",
                      "cancelButton"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="empty-container"></div>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
}
export default PaymentSelectionPage;

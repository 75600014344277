import React from "react";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { translateComponent } from "../../utils/services/translate";

// CSS
import "./Navigation.scss";

// Actions
import { setShowNavigationMenu } from "../../redux/actions";
import AccordionButtonComponent from "./AccordionButtonComponent";

// utils
import { getDefaultProject } from "../../utils/localStorage";

function BuyTicket(navigate, dispatch) {
  dispatch(setShowNavigationMenu(false));
  navigate("/");
}
function NavigateAuthPages(url, navigate, dispatch) {
  dispatch(setShowNavigationMenu(false));
  navigate(url);
}

function Navigation() {
  const show_navigation = useSelector((state) => state.Layout.show_navigation);
  const disable_navigation = useSelector(
    (state) => state.Layout.disable_navigation
  );
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const projectData = useSelector((state) => state.Project.project);
  let termsUrl = projectData?.termsUrl;
  let faqUrl = projectData?.faqUrl;
  const { isAuthed, logout, phoneNumber } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (projectData === null) {
    try {
      termsUrl = getDefaultProject().termsUrl;
      faqUrl = getDefaultProject().faqUrl;
    } catch {
      termsUrl = "https://nobina.se";
      faqUrl = "https://nobina.se";
    }
  }
  if (show_navigation && !disable_navigation) {
    return (
      <React.Fragment>
        <div className="navigation min-height-100">
          <div className="menu-container pl-4">
            <div className="menu-container-content">
              <div className="list-menu pt-3">
                {isAuthed() ? (
                  <div className="m-4 bg-green text-white p-3 pb-4">
                    <h3 className="text-xl p-2 text-extra-bold text-white d-inline">
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "title"
                      )}
                    </h3>

                    <span className="text-m text-white float-end">
                      ({phoneNumber()})
                    </span>

                    <div
                      className="mypages-text pt-0 mt-2"
                      onClick={() =>
                        NavigateAuthPages("/home/tickets", navigate, dispatch)
                      }
                    >
                      <span className="btn-chevron-right-white float-start me-2"></span>
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "myTicketsPageText"
                      )}
                    </div>
                    <div
                      className="mypages-text"
                      onClick={() =>
                        NavigateAuthPages("/home/orders", navigate, dispatch)
                      }
                    >
                      <span className="btn-chevron-right-white float-start me-2"></span>
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "myOrdersPageText"
                      )}
                    </div>
                  </div>
                ) : null}
                {isAuthed() ? (
                  <div className="list-item m-4">
                    <AccordionButtonComponent></AccordionButtonComponent>
                    <div className="pt-4 border-green border-b h-50"></div>
                  </div>
                ) : (
                  <div className="list-item m-4">
                    <button
                      className="btn btn-success w-100 text-lg"
                      onClick={() => BuyTicket(navigate, dispatch)}
                    >
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "buyNewTicketButton"
                      )}
                    </button>
                    <div className="pt-4 border-green border-b h-50"></div>
                  </div>
                )}

                <div className="list-item m-4">
                  {language === "sv" ? (
                    <a
                      className="text-lg p-2"
                      href={faqUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "howTicketWorkPageText"
                      )}
                    </a>
                  ) : (
                    <a
                      className="text-lg p-2"
                      href={faqUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "howTicketWorkPageText"
                      )}
                    </a>
                  )}

                  <div className="pt-4 border-green border-b h-50"></div>
                </div>

                <div className="list-item m-4">
                  {language === "sv" ? (
                    <a
                      className="text-lg p-2"
                      href={termsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "agreeAndPrivacyPageText"
                      )}
                    </a>
                  ) : (
                    <a
                      className="text-lg p-2"
                      href={termsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translateComponent(
                        translations,
                        language,
                        "navigationMenu",
                        "agreeAndPrivacyPageText"
                      )}
                    </a>
                  )}
                  <div className="pt-4 border-green border-b h-50"></div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 p-4 text-center">
                    <div className="mypages-container">
                      {!isAuthed() ? (
                        <>
                          <div className="row">
                            <div className="col-12 p-4 text-center bg-green text-white pb-5 ">
                              <h2 className="text-start pb-3">
                                {" "}
                                {translateComponent(
                                  translations,
                                  language,
                                  "navigationMenu",
                                  "title"
                                )}
                              </h2>
                              <Link
                                to={"/login"}
                                className="btn btn-success ml-4 p-2 w-100"
                              >
                                {translateComponent(
                                  translations,
                                  language,
                                  "navigationMenu",
                                  "loginButton"
                                )}
                              </Link>
                              <p className="text-m text-white mt-2">
                                {translateComponent(
                                  translations,
                                  language,
                                  "navigationMenu",
                                  "loginText1"
                                )}
                                <br />
                                {translateComponent(
                                  translations,
                                  language,
                                  "navigationMenu",
                                  "loginText2"
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-12 p-4 text-center text-white pt-4 pb-5 ">
                              <button
                                onClick={() => logout()}
                                className="btn btn-secondary mb-5 w-100"
                              >
                                {translateComponent(
                                  translations,
                                  language,
                                  "navigationMenu",
                                  "logoutButton"
                                )}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default Navigation;

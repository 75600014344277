// @flow
import { CategoryActionTypes } from "./constants";

const INIT_STATE = {
  category_list: null,
};

type CategoryAction = { type: string, payload?: string | null };

type State = {};

const Category = (state: State = INIT_STATE, action: CategoryAction): any => {
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORY_LIST:
      return {
        ...state,
        category_list: action.payload,
      };
    default:
      return state;
  }
};

export default Category;

// @flow
import { all } from 'redux-saga/effects';



function* LayoutSaga(): any {
    yield all([
    ]);
}


export default LayoutSaga;

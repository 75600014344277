import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { translatePage } from "../../../../utils/services/translate";

// redux
import {
  setLoading,
  setLoadingMessage,
  setTicketList,
} from "../../../../redux/actions";

//api
import { getTicket } from "../../../../utils/api/restClient";

// components
import { LoadingComponent } from "../../../../components/layout/loading/LoadingComponent";
import TicketListCarusell from "../../../../components/ticket/ticketListCarusell";

function TicketPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Layout.loading);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const { orderReferenceId, ticketId } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);

    const getTicketFromApi = async (ticketId) => {
      dispatch(setTicketList(null));
      try {
        dispatch(
          dispatch(
            setLoadingMessage(
              "Hämtar biljett status.",
              "Getting ticket status."
            )
          )
        );
        const res = await getTicket(ticketId);
        if (res !== undefined && res.referenceId) {
          dispatch(setTicketList([res]));
        } else {
          dispatch(setTicketList(null));
        }
        dispatch(setLoading(false));
      } catch (error) {
        console.error("Error fetching ticket:", error);
        dispatch(setLoading(false));
        dispatch(setTicketList(null));
      }
    };

    getTicketFromApi(ticketId);
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }
  return (
    <div className="container min-height-100 mt-5">
      <div className="row pt-4">
        <div className="col-12 col-centered">
          <>
            <Link
              id={"page_landingPage_myPages"}
              className=" text-dark pe-2 text-xl pb-3 "
              to={"/home/orders/" + orderReferenceId}
            >
              {translatePage(
                translations,
                language,
                "ticketPage",
                "BackButtonText"
              )}{" "}
            </Link>
          </>
        </div>
        <div className="col-12 col-centered">
          <TicketListCarusell />
        </div>
      </div>
    </div>
  );
}

export default TicketPage;

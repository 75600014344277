import React from "react";
// Translation
import { translateComponent } from "../../../utils/services/translate";

const SwishErrorComponent = (props) => {
  if (props.swishResponse === null || props.swishResponse === undefined) {
    return null;
  }
  if (props.swishResponse.status === "DECLINED") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {" "}
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "DeclinedErrorh3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <h5 className="text-extra-bold mt-2"> </h5>
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "DeclinedErrorMessage"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "RF07") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {" "}
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "RF07Errorh3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <h5 className="text-extra-bold mt-2"> </h5>
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "RF07ErrorMessage1"
                )}
              </p>
              <h5 className="text-extra-bold mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "RF07Errorh5"
                )}
              </h5>
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "RF07Message2"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "BANKIDCL") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "BANKIDCLh3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "BANKIDCLmessage"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "FF10") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "FF10h3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "FF10message"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "TM01") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "TM01h3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "TM01message"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "DS24") {
    return (
      <>
        <div className="row">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "TM01h3"
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="">
            <div className="col-12">
              <p className="mt-2">
                {translateComponent(
                  props.translations,
                  props.language,
                  "swishErrorComponent",
                  "DS24message"
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.swishResponse.errorCode === "VR01") {
    return (
      <>
        <div className="row mb-3">
          <div className="col-12 bg-light rounded text-center">
            <h3 className="mt-2 ">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "VR01message"
              )}
            </h3>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-12 bg-light rounded text-center">
          <h3 className="mt-2">
            {translateComponent(
              props.translations,
              props.language,
              "swishErrorComponent",
              "generalTechError"
            )}
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="col-12">
            <p className="mt-2">
              {translateComponent(
                props.translations,
                props.language,
                "swishErrorComponent",
                "generalTechErrorMessage"
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwishErrorComponent;

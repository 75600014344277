export function saveToken(referenceId, seconds, token, color) {
  seconds = seconds + 2; // Subtract 2 seconds to make sure the token is valid when it's used
  const now = new Date().getTime();
  const newTokenItem = {
    item: referenceId,
    color: color,
    token: token,
    expiry: now + seconds * 1000,
  };

  const existingListJSON = localStorage.getItem("NFP");
  let tokenList = [];

  if (existingListJSON) {
    tokenList = JSON.parse(existingListJSON);
  }

  tokenList.push(newTokenItem);

  localStorage.setItem("NFP", JSON.stringify(tokenList));
}

export function getToken(referenceId) {
  const tokenListString = localStorage.getItem("NFP");
  if (tokenListString) {
    const secretList = JSON.parse(tokenListString);
    if (secretList.length > 0) {
      const secrets = secretList.filter(
        (secret) => secret.item === referenceId
      );

      if (secrets.length > 0) {
        // select token with latest expiry
        const latestSecret = secrets.reduce((latest, current) => {
          return current.expiry > latest.expiry ? current : latest;
        });
        if (latestSecret.token) {
          return latestSecret.token;
        }
      }
    }
  }
  return null;
}

export function getActivationColor(referenceId) {
  const tokenListString = localStorage.getItem("NFP");
  if (tokenListString) {
    const secretList = JSON.parse(tokenListString);

    if (secretList.length > 0) {
      const secret = secretList.find((secret) => secret.item === referenceId);
      if (secret && secret.color) {
        return secret.color;
      }
    }
  }
  return null;
}

export function removeExpiredTokens() {
  const tokenListString = localStorage.getItem("NFP");

  if (tokenListString) {
    const tokenList = JSON.parse(tokenListString);
    const now = Date.now();

    const updatedTokenList = tokenList.filter(
      (tokenItem) => tokenItem.expiry > now
    );

    localStorage.setItem("NFP", JSON.stringify(updatedTokenList));
  }
}

export function getLatestValidDate(ticket) {
  if (
    ticket &&
    Array.isArray(ticket.validationAttempts) &&
    ticket.validationAttempts.length > 0
  ) {
    ticket.validationAttempts.sort(
      (a, b) => new Date(b.verified) - new Date(a.verified)
    );

    return ticket.validationAttempts[0];
  } else {
    return { verified: new Date().toISOString() };
  }
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { translateComponent } from "../../utils/services/translate";
import "./orderList.scss";

import currencyLabel from "../../utils/helper/currencyLabel";

const OrderList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const navigate = useNavigate();

  const {
    loading,
    order_list,
    order_list_selected_id,
    language,
    translations,
  } = useSelector((state) => ({
    loading: state.Layout.loading,
    order_list: state.Order.order_list,
    order_list_selected_id: state.Order.order_list_selected_id,
    language: state.Layout.language,
    translations: state.Layout.translations,
  }));

  if (order_list_selected_id !== null) {
    let url = `/home/orders/${order_list_selected_id}`;
    return <Navigate to={url} replace={true} />;
  }

  const currentOrders =
    order_list && order_list.length
      ? order_list.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      : [];
  const pageCount = order_list
    ? Math.ceil(order_list.length / itemsPerPage)
    : 0;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPageNumbers = () => {
    return [...Array(pageCount).keys()].map((number) => (
      <li key={number + 1} className="page-item">
        <button onClick={() => paginate(number + 1)} className="page-link">
          {number + 1}
        </button>
      </li>
    ));
  };

  const setSelectedItem = (referenceId) => {
    navigate(`/home/orders/${referenceId}`);
  };

  if (currentOrders.length > 0) {
    return (
      <>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-xl">
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "orderList",
                    "date"
                  )}
                </th>

                <th className="text-xl text-center">
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "orderList",
                    "sum"
                  )}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order, index) => (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => setSelectedItem(order.referenceId)}
                    className={`order-table-row`}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="text-lg">
                      {moment(order.createdDateTime).format("YYYY-MM-DD HH:mm")}
                    </td>

                    <td className="text-center text-lg">
                      {order.amount} {currencyLabel(language, order.currency)}
                    </td>

                    <td className="text-lg">
                      <div className="align-right">
                        <button className="chevron-btn-right"></button>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <nav>
          <ul className="pagination">{renderPageNumbers()}</ul>
        </nav>
      </>
    );
  } else if (!loading) {
    return (
      <p id="component_orderList_order_missing" className="text-center mt-5">
        {translateComponent(
          translations,
          language,
          "orderList",
          "order_missing"
        )}
      </p>
    );
  }

  return null;
};

export default OrderList;

import React from "react";

const CookiePage = () => {
  return (
    <div className="container min-height-100">
      <div className="row">
        <div className="col-12">
          <h1 className="mt-5 pt-5  ">Cookies</h1>
          <p>
            We use cookies on our websites. A cookie is a small text file that
            is saved on your computer and facilitates certain functions, such as
            navigating the website. We also use cookies to collect statistics
            about the number of visitors to our website, to analyse visitor
            behaviour and to improve the website’s functionality and the user’s
            experience of the websites. This information enables us to develop
            and optimize the websites.
          </p>
          <p className="text-m">
            The information collected by Nobina through cookies on our websites
            is only used for Nobina’s purposes, and not for third party
            purposes. Cookies are sometime used to collect information that is
            considered personal data, such as IP addresses and information
            linked to the IP address, but no personal data that is otherwise
            directly associated with you as an individual.
          </p>
          <p className="text-m">
            Cookies may either be automatically erased when you close your
            browser (session cookies) or alternatively stored on your computer
            to facilitate future visits to the website (persistent cookies).
            Persistent cookies must also be automatically erased after a
            specified time. You can always erase cookies from your browser. The
            first-party cookies used on our website are described below.
          </p>
          <table className="border table-responsives">
            <thead className="bg-light border">
              <tr>
                <th className="p-2">Cookie</th>
                <th className="p-2">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border" key="1">
                <td className="p-2">
                  <strong>BearerToken</strong>
                </td>
                <td className="p-2">
                  Our <strong>BearerToken</strong> is designed to authenticate
                  users during their interactions with the application. It
                  ensures that each request from the user is valid and
                  originates from the expected source. This token has a validity
                  period of 7 days, striking a balance between convenience for
                  our users and the need for high security. During this period,
                  users can securely communicate with the application without
                  the need to log in again. To further enhance security, the
                  bearerToken is stored in HTTP Secure cookies, which restricts
                  cookie access to HTTPS transfers only and prevents them from
                  being read by client-side JavaScript.
                </td>
              </tr>
              <tr key="2">
                <td className="p-2">
                  <strong>RefreshToken</strong>
                </td>
                <td className="p-2">
                  To facilitate a smooth user experience, even after the
                  <strong> BearerToken</strong> has expired, we use a{" "}
                  <strong>RefreshToken</strong>. This token has a longer
                  lifespan of up to 90 days and is used to securely renew the
                  bearerToken without requiring the user to undergo a new login
                  process. This system ensures that users remain logged in even
                  if they do not use the application daily, while still
                  maintaining a high level of security. Like the bearerToken,
                  the refreshToken is also stored in HTTP Secure cookies to
                  ensure it is protected from unauthorized access attempts.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-m mt-2">
            To further bolster security, we have implemented a policy where all
            previous tokens are automatically revoked upon the issuance of new
            tokens, whether through a new login or through token renewal with a
            refreshToken. This reduces the risk of misuse of potentially
            compromised tokens and ensures that only the latest, valid tokens
            can be used for access to user accounts. Through this responsible
            management of authentication tokens, we aim to protect our users'
            privacy and security while offering a convenient and hassle-free
            user experience.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h1 className="">LocalStorage</h1>

          <p className="">
            As part of our commitment to providing a personalized and efficient
            user experience, our application utilizes the localStorage feature
            of your web browser. This functionality is used to store specific
            settings directly on your device, ensuring that your preferences are
            remembered and applied during your use of our app. We believe in
            being transparent about the data we store and the methods we use, so
            here’s a breakdown of what’s stored and why:
          </p>

          <h3>Variables Stored in LocalStorage:</h3>
          <ul className="text-m">
            <li>
              <strong>default-project:</strong> This variable remembers your
              last selected project or the project you have set as default,
              enabling you to quickly access your preferred project without
              needing to select it each time you visit our app.
            </li>
            <li>
              <strong>lang:</strong> This setting saves your language
              preference, allowing the app to automatically display in the
              language you’ve chosen for an enhanced browsing experience.
            </li>
            <li>
              <strong>cookiesEnable:</strong> This confirms that you have
              accepted our use of cookies. It’s a reminder of your consent,
              ensuring that we adhere to your preferences regarding cookie
              usage.
            </li>
            <li>
              <strong>tokenExpireAt:</strong> timestamp for when the token
              expires. JavaScript cannot read HTTP Secure cookies therefore we
              store the token expiration time in localStorage to be able to
              check if the token is expired.
            </li>
            <li>
              <strong>refreshTokenExpireAt:</strong> timestamp for when the
              token expires. JavaScript cannot read HTTP Secure cookies
              therefore we store the token expiration time in localStorage to be
              able to check if the token is expired.
            </li>
            <li>
              <strong>phoneNumber:</strong> your phoneNumber is stored in the
              app. This is used to prefill the phone number in the checkout
              form.
            </li>
          </ul>

          <h3>Privacy and Control:</h3>
          <p className="text-m">
            No External Storage: It’s important to note that no information
            stored in localStorage is transmitted to our servers or stored
            externally. All data remains on your device, offering you complete
            control over your personal settings and information.
          </p>
          <p className="text-m">
            User-Controlled: You have full control over the data stored in
            localStorage. If you wish to modify or delete this information, you
            can easily do so through your browser settings, offering you
            flexibility and autonomy over your data.
          </p>
          <h3>Purpose of Storage:</h3>
          <p className="text-m">
            The sole purpose of storing these variables is to enhance your user
            experience by remembering your preferences and settings. This
            approach allows for a more personalized, convenient, and efficient
            interaction with our application, without compromising your privacy
            or data security.
          </p>

          <h3>Ensuring Your Privacy:</h3>
          <p className="text-m">
            We are dedicated to protecting your privacy and ensuring a secure
            online experience. The use of localStorage is a part of this
            commitment, enabling us to provide a personalized service while
            keeping your data safe and under your control.
          </p>
          <p className="text-m">
            If you have any questions or concerns about our use of localStorage
            or any other privacy matters, please do not hesitate to contact us.
            Your trust and safety are our top priorities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePage;

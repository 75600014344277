import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setLoading,
  setCategoryList,
  setProjectList,
  setLoadingMessage,
} from "../../redux/actions";
import { getDefaultProject } from "../../utils/localStorage";
import { getCategories, getProjects } from "../../utils/api/restClient";
import ProductCategoryList from "../../components/product/productCategoryList";
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";

const NO_DATA_ROUTE = "/404";
const LOADING_MESSAGE = {
  default: "Rullar fram..",
  extended: "Getting there...",
};

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.Layout.loading);
  const projectData = useSelector((state) => state.Project.project_list);

  const handleDefaultProject = useCallback(() => {
    const project = getDefaultProject();
    if (project) {
      dispatch();
      navigate(project.slug);
    } else {
      window.location.replace(
        "https://nobina.se/vara-losningar/hyr-buss/sweden-rock/"
      );
    }
  }, [navigate]);

  const handleApiError = useCallback(
    (error) => {
      console.error("API call failed:", error);
      navigate(NO_DATA_ROUTE);
    },
    [navigate]
  );

  const fetchProjects = useCallback(async () => {
    try {
      dispatch(
        setLoadingMessage(LOADING_MESSAGE.default, LOADING_MESSAGE.extended)
      );
      const projects = await getProjects();

      if (projects.length > 0) dispatch(setProjectList(projects));
      else throw new Error("No projects found");
    } catch (error) {
      handleApiError(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, handleApiError]);

  const fetchCategories = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const categories = await getCategories();

      if (categories.length > 0) dispatch(setCategoryList(categories));
      else throw new Error("No categories found");
    } catch (error) {
      handleApiError(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, handleApiError]);

  useEffect(() => {
    handleDefaultProject();
    fetchCategories();
    fetchProjects();
  }, [handleDefaultProject, fetchCategories, fetchProjects]);

  if (loading) return <LoadingComponent />;

  return (
    <div className="container min-vh-100 mt-5 pt-5">
      {projectData ? (
        <ProductCategoryList />
      ) : (
        <div className="mt-5">No data</div>
      )}
    </div>
  );
}

export default LandingPage;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import { translateComponent } from "../../utils/services/translate";

// REDUX
import { setMyProjectList, setShowNavigationMenu } from "../../redux/actions";

// API
import { getMyProjects } from "../../utils/api/restClient";

// Styles
import "./AccordionButtonComponent.scss";

const AccordionButtonComponent = ({ children }) => {
  const [open, setOpen] = useState(false);
  // const [myProjectLoaded, setMyProjectLoaded] = useState(false);
  const projects = useSelector((state) => state.Project.my_project_list);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function BuyTicket(navigate, dispatch) {
    dispatch(setShowNavigationMenu(false));
    navigate("/");
  }

  useEffect(() => {
    // const fetchProjectsApi = async () => {
    //   try {
    //     let res = await getMyProjects();
    //     setMyProjectLoaded(true);
    //     if (res.length > 0) {
    //       dispatch(setMyProjectList(res));
    //     }
    //   } catch {
    //     setMyProjectLoaded(true);
    //   }
    // };
    // fetchProjectsApi();
    // disable
  }, []);

  function navigateToProject(project, dispatch) {
    dispatch(setShowNavigationMenu(false));
    window.open(project.slug, "_self");
  }
  function projectComponent(project, dispatch) {
    return (
      <button
        key={project.id}
        className="navbar-accordion-item-button d-block ms-4 pb-2 text-dark text-lg"
        onClick={() => {
          navigateToProject(project, dispatch);
        }}
      >
        {translateProjectName(project)}
      </button>
    );
  }
  function translateProjectName(project) {
    if (language === "sv") {
      return project.displayNameSvSe;
    }
    return project.displayNameEnUs;
  }
  const contentStyle = {
    maxHeight: open ? "1000px" : "0",
    overflow: "hidden",
  };

  return (
    <button
      className="btn btn-success w-100 text-lg"
      onClick={() => BuyTicket(navigate, dispatch)}
    >
      {translateComponent(
        translations,
        language,
        "navigationMenu",
        "buyNewTicketButton"
      )}
    </button>
  );
  // if (projects !== null && projects.length > 1) {
  //   return (
  //     <div className="w-100">
  //       <div
  //         className="navbar-accordion-button btn-none w-100 m-0 p-0 text-lg"
  //         onClick={() => setOpen(!open)}
  //         aria-expanded={open}
  //       >
  //         <div className="text-start">
  //           <div className="ms-2 navbar-accordion-button-text d-inline text-dark">
  //             {translateComponent(
  //               translations,
  //               language,
  //               "navigationMenu",
  //               "buyNewTicketButton"
  //             )}
  //           </div>
  //           {open ? (
  //             <span className="btn-chevron-down"></span>
  //           ) : (
  //             <span className="btn-chevron-down"></span>
  //           )}
  //         </div>
  //       </div>
  //       <div style={contentStyle}>
  //         <div className="bg-light pt-2">
  //           {projects.map((project) => {
  //             return projectComponent(project, dispatch);
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   if (myProjectLoaded) {
  //     return (
  //       <button
  //         className="btn btn-success w-100 text-lg"
  //         onClick={() => BuyTicket(navigate, dispatch)}
  //       >
  //         {translateComponent(
  //           translations,
  //           language,
  //           "navigationMenu",
  //           "buyNewTicketButton"
  //         )}
  //       </button>
  //     );
  //   } else {
  //     return null;
  //   }
  // }
};

export default AccordionButtonComponent;

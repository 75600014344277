const local_storage_token_location = "ext-at";
const lang = "prefered-lang";
const projectKey = "nb-defult-project";

export function removeDefaultProject() {
  localStorage.removeItem("nb-defult-project");
}
export function setDefaultProject(project) {
  let jsonString = JSON.stringify(project);
  localStorage.setItem("nb-defult-project", jsonString);
}
export function getDefaultProject() {
  var project = localStorage.getItem("nb-defult-project");
  if (project === null) {
    return null;
  } else {
    return JSON.parse(project);
  }
}
export function setPreferedLanguage(locale) {
  localStorage.setItem(lang, locale);
}
export function getPreferedLanguage() {
  if (localStorage.getItem(projectKey) === null) {
    // return default lang.
    return "en-US";
  } else {
    let selectedProject = null;
    try {
      selectedProject = JSON.parse(localStorage.getItem(projectKey));
    } catch {
      return "en-US";
    }

    if (validateProjectDataScheme(selectedProject).success === false) {
      return "en-US";
    }
    if (localStorage.getItem(lang) === null) {
      // return projects default lang.
      try {
        return selectedProject.languages[0];
      } catch {
        return "en-US";
      }
    } else {
      let localLang = localStorage.getItem(lang);
      if (selectedProject.languages.includes(localLang)) {
        // return user defined lang.
        return localLang;
      } else {
        // user defined lang does not exist in project. change to project defualt lang.
        try {
          return selectedProject.languages[0];
        } catch {
          return "en-US";
        }
      }
    }
  }
}
export function getLanguageOptions() {
  if (localStorage.getItem(projectKey) === null) {
    return ["en-US"];
  } else {
    try {
      let selectedProject = JSON.parse(localStorage.getItem(projectKey));
      return selectedProject.languages;
    } catch {
      return ["en-US"];
    }
  }
}

export function tokenExist() {
  if (localStorage.getItem(local_storage_token_location) === null) {
    return false;
  }
  return true;
}

function validateProjectDataScheme(data) {
  if (!data.translations || !Array.isArray(data.translations)) {
    return {
      success: false,
      message: "Translations (data.Property) is missing",
    };
  }
  if (!data.paymentOptions || !Array.isArray(data.paymentOptions)) {
    return {
      success: false,
      message: "PaymentOptions (data.Property) is missing",
    };
  }
  if (!data.categories || !Array.isArray(data.categories)) {
    return { success: false, message: "Categories (data.Property) is missing" };
  }
  if (!data.languages || !Array.isArray(data.languages)) {
    return { success: false, message: "Languages is missing" };
  }

  if (data.translations.length < 3) {
    // console.log("missing translation in array");
  }

  return { success: true, message: "Data is valid." };
}

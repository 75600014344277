// @flow
import React, { Suspense } from "react";
import Topbar from "./Topbar";
import TopDialog from "./TopDialog";
import CookieConsent from "./CookieConsent";
const loading = () => <div className=""></div>;

type DefaultLayoutProps = {
  children?: any,
};

const DefaultLayout = (props: DefaultLayoutProps): React$Element<any> => {
  const children = props.children || null;
  return (
    <>
      <Topbar />
      <TopDialog />
      <CookieConsent />
      <Suspense fallback={loading()}>{children}</Suspense>
    </>
  );
};
export default DefaultLayout;

// @flow
import { LoginActionTypes } from "./constants";

type LoginAction = { type: string, payload?: string | null };

export const setSmsSent = (bool): LoginAction => ({
  type: LoginActionTypes.SET_SMS_SENT,
  payload: bool,
});
export const setSmsSentFailure = (bool): LoginAction => ({
  type: LoginActionTypes.SET_SMS_SENT_FAILURE,
  payload: bool,
});
export const setPhoneNumber = (text): LoginAction => ({
  type: LoginActionTypes.SET_PHONE_NUMBER,
  payload: text,
});
export const setPhoneChar = (number): LoginAction => ({
  type: LoginActionTypes.SET_PHONE_CHAR,
  payload: number,
});
export const setPhoneValid = (bool): LoginAction => ({
  type: LoginActionTypes.SET_PHONE_VALID,
  payload: bool,
});
export const setVerificationCodeValid = (bool): LoginAction => ({
  type: LoginActionTypes.SET_VERIFICATION_CODE_VALID,
  payload: bool,
});
export const setVerificationCode = (text): LoginAction => ({
  type: LoginActionTypes.SET_VERIFICATION_CODE,
  payload: text,
});
export const setVerificationCodeLength = (number): LoginAction => ({
  type: LoginActionTypes.SET_VERIFICATION_CODE_LENGTH,
  payload: number,
});

export const resetLoginStates = (): LoginAction => ({
  type: LoginActionTypes.RESET_LOGIN_STATES,
  payload: true,
});
export const setVerificationCodeNotAccepted = (bool): LoginAction => ({
  type: LoginActionTypes.SET_VERIFICATION_CODE_NOT_ACCEPTED,
  payload: bool,
});
export const setUnauthorized = (bool): LoginAction => ({
  type: LoginActionTypes.SET_UNAUTHORIZED,
  payload: bool,
});

import { useSelector } from "react-redux";
import { translateComponent } from "../../utils/services/translate";
// utils
import moment from "moment";

//scss
import "./ticketInformationDialog.scss";
export function TicketInformationDialog({ ticket, isOpen, onClose }) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);

  const getExpireDate = (ticket) => {
    if (ticket.status !== "used") {
      return (
        <tr>
          <th scope="row" className="text-extra-bold m-0 p-0">
            {translateComponent(
              translations,
              language,
              "ticketInformationDialogComponent",
              "expireDate"
            )}
          </th>
          <td className="m-0 p-0">
            {moment(ticket.expirationDate).format("YYYY-MM-DD HH:mm")}
          </td>
        </tr>
      );
    }
  };
  const getActivationCount = (ticket) => {
    if (ticket.validationAttempts.length === 0) {
      return (
        <tr>
          <th scope="row" className="text-extra-bold m-0 p-0 pb-2">
            {translateComponent(
              translations,
              language,
              "ticketInformationDialogComponent",
              "activations"
            )}
          </th>
          <td className="m-0 p-0 pb-2">{`0 av ${ticket.maxAllowedAttempts}`}</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <th scope="row" className="text-extra-bold m-0 p-0 pb-2">
            {translateComponent(
              translations,
              language,
              "ticketInformationDialogComponent",
              "activations"
            )}
          </th>
          <td className="m-0 p-0 pb-2">{`${ticket.validationAttempts.length} av ${ticket.maxAllowedAttempts}`}</td>
        </tr>
      );
    }
  };
  const getActivationLog = (ticket) => {
    if (ticket.validationAttempts.length > 0) {
      return (
        <>
          <h3 className="text-center bg-dark text-white p-2">
            {" "}
            {translateComponent(
              translations,
              language,
              "ticketInformationDialogComponent",
              "whenTicketWasUsed"
            )}
          </h3>
          <div
            className="custom-scrollable-list"
            style={{ height: "85px", overflowY: "auto" }}
          >
            <table className="table borderless">
              <tbody>
                {ticket.validationAttempts.map((item, index) => (
                  <tr key={index} className="">
                    <td className="text-extra-bold">
                      {translateComponent(
                        translations,
                        language,
                        "ticketInformationDialogComponent",
                        "date"
                      )}
                    </td>
                    <td className="">
                      {moment(item.verified).format("YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return null;
    }
  };
  if (!isOpen) return null;
  let ticketStatus = ticket.status;
  return (
    <div className="information-dialog bg-white text-dark text-start h-100">
      <div className="content-container">
        <div className="container">
          <p className="text-xxs text-center bg-light mt-2 ">
            {" "}
            <strong>Id:</strong> {ticket.referenceId}
          </p>
          <table className="table borderless">
            <tbody>
              <tr>
                <th scope="row" className="text-extra-bold m-0 p-0">
                  Status:
                </th>
                <td className="m-0 p-0">
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    ticketStatus
                  )}
                </td>
              </tr>
              {getExpireDate(ticket)}
              {getActivationCount(ticket)}
            </tbody>
          </table>

          {getActivationLog(ticket)}
        </div>
      </div>

      <div className="col-12 pb-3 pe-2 ps-2">
        <button className="btn btn-secondary w-100" onClick={onClose}>
          {translateComponent(
            translations,
            language,
            "ticketInformationDialogComponent",
            "closeButtonText"
          )}
        </button>
      </div>
    </div>
  );
}

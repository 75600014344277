// @flow
import { TicketActionTypes } from "./constants";

type TicketAction = { type: string, payload?: string | null };

export const setSelectedTicket = (data): TicketAction => ({
  type: TicketActionTypes.SET_SELECTED_TICKET,
  payload: data,
});
export const setTicketNotFound = (data): TicketAction => ({
  type: TicketActionTypes.SET_TICKET_NOT_FOUND,
  payload: data,
});
export const setTicketList = (data): TicketAction => ({
  type: TicketActionTypes.SET_TICKET_LIST,
  payload: data,
});
export const setTicketListSelectedId = (number): TicketAction => ({
  type: TicketActionTypes.SET_TICKET_LIST_SELECTED_ID,
  payload: number,
});
export const updateTicket = (ticket): TicketAction => ({
  type: TicketActionTypes.UPDATE_TICKET,
  payload: ticket,
});

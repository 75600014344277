import React from "react";
import { useDispatch } from "react-redux";

// redux
import { setSelectedProduct } from "../../redux/actions";

import { getPreferedLanguage } from "../../utils/localStorage";
//components
import ProductsDescriptionHtml from "./productsDescriptionHtml";
import { translateComponent } from "../../utils/services/translate";

//redux
import { useSelector } from "react-redux";

function ProductDescription(props) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);

  let preferedLanguage = getPreferedLanguage();
  let translation = props.product.translations.find(
    (translation) => translation.locale === preferedLanguage
  );
  // fallback to en-US if no translation is found
  if (translation === undefined) {
    translation = props.product.translations.find(
      (translation) => translation.locale === "en-US"
    );
  }
  const dispatch = useDispatch();
  return (
    <div className="product-container bg-white rounded p-2 mb-5 mt-1 pb-3">
      <ProductsDescriptionHtml
        html={translation.detailsHtml}
      ></ProductsDescriptionHtml>

      <div className="text-center">
        <div className="pt-1 pb-2 border-green border-b h-50"></div>
        <button
          className="btn btn-link text-center text-m m-0 p-0 w-100 pt-2"
          onClick={() => {
            dispatch(setSelectedProduct(null));
          }}
        >
          {translateComponent(
            translations,
            language,
            "productDescription",
            "closeButtonText"
          )}
        </button>
      </div>
    </div>
  );
}

export default ProductDescription;

// TopDialog.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLayoutShowError } from "../../redux/actions";
import { translateComponent } from "../../utils/services/translate";
import "./TopDialog.scss";

const TopDialog = () => {
  const isOpen = useSelector((state) => state.Layout.show_error_message);
  const messageData = useSelector((state) => state.Layout.error_message);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const dispatch = useDispatch();

  if (!isOpen || messageData == null) return null;
  return (
    <div className="top-dialog-backdrop pt-5 mt-5">
      <div className="top-dialog">
        <h1>{messageData.header}</h1>
        <p className="mb-5">{messageData.text}</p>

        <button
          className="btn btn-secondary w-100"
          onClick={() => dispatch(setLayoutShowError(false))}
        >
          {translateComponent(
            translations,
            language,
            "topDialog",
            "closeButtonText"
          )}
        </button>
      </div>
    </div>
  );
};

export default TopDialog;

export const OrderActionTypes = {
  ADD_TO_BASKET: "@@order/ADD_TO_BASKET",
  SET_FRIENDLY_NAME_NUMBER: "@@order/SET_FRIENDLY_NAME_NUMBER",
  SET_SELECTED_ORDER: "@@order/SET_SELECTED_ORDER",
  SET_SELECTED_ORDER_REFUND_STATUS: "@@order/SET_SELECTED_ORDER_REFUND_STATUS",
  SET_ORDER_NOT_FOUND: "@@order/SET_ORDER_NOT_FOUND",
  SET_FAILED_TO_SEND_ORDER: "@@order/SET_FAILED_TO_SEND_ORDER",
  SET_ORDER_LIST: "@@order/SET_ORDER_LIST",
  SET_ORDER_LIST_SELECTED_ID: "@@order/SET_ORDER_LIST_SELECTED_ID",
};

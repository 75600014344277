// @flow
import { ProductActionTypes } from "./constants";

const INIT_STATE = {
  product_list: null,
  selected_product: null,
  product_list_filtered_by_category: null,
};

type ProductAction = { type: string, payload?: string | null };

type State = {};

const Product = (state: State = INIT_STATE, action: ProductAction): any => {
  switch (action.type) {
    case ProductActionTypes.SET_PRODUCT_LIST:
      return {
        ...state,
        product_list: action.payload,
      };
    case ProductActionTypes.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selected_product: action.payload,
      };
    case ProductActionTypes.SET_SELECTED_PRODUCT_BY_CATEGORY:
      if (action.payload == null) {
        return {
          ...state,
          selected_product_by_category: null,
        };
      } else {
        const filteredProducts = state.product_list.filter((product) =>
          product.categories.some(
            (category) => category.name === action.payload
          )
        );

        return {
          ...state,
          product_list_filtered_by_category:
            filteredProducts.length > 0 ? filteredProducts : null,
        };
      }
    default:
      return state;
  }
};

export default Product;

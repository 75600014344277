import axios from "axios";
import { loginRefresh, loginRefreshV2 } from "./protectedClientHelper";

const protectedClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URI,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

protectedClient.interceptors.request.use(
  async (config) => {
    if (
      config.url !== "/api/identity/login/passcode" &&
      config.url !== config.baseURL + "/api/identity/login/passcode" &&
      config.url !== "/api/identity/authorized" &&
      config.url !== config.baseURL + "/api/identity/authorized"
    ) {
      const tokenExpireAtString = localStorage.getItem("tokenExpireAt");
      const currentTime = new Date().getTime();
      if (tokenExpireAtString !== null && !tokenExpireAtString) {
        localStorage.removeItem("tokenExpireAt");
        localStorage.removeItem("refreshTokenExpireAt");
        localStorage.removeItem("phoneNumber");
        window.location.href = "/login";
        return;
      }
      const tokenExpireAt = new Date(tokenExpireAtString);

      if (tokenExpireAt && currentTime > tokenExpireAt.getTime()) {
        const refreshTokenExpireAtString = localStorage.getItem(
          "refreshTokenExpireAt"
        );

        if (!refreshTokenExpireAtString) {
          localStorage.removeItem("tokenExpireAt");
          localStorage.removeItem("refreshTokenExpireAt");
          localStorage.removeItem("phoneNumber");
          window.location.href = "/login";
          return;
        }

        const refreshTokenExpireAt = new Date(refreshTokenExpireAtString);

        if (
          refreshTokenExpireAt &&
          currentTime > refreshTokenExpireAt.getTime()
        ) {
          localStorage.removeItem("tokenExpireAt");
          localStorage.removeItem("refreshTokenExpireAt");
          localStorage.removeItem("phoneNumber");
          window.location.href = "/login";
          return;
        } else {
          await loginRefresh();
        }
      }
    } else {
      // Exclude urls
      if (
        config.url !== "/api/identity/login/passcode" &&
        config.url !== config.baseURL + "/api/identity/login/passcode"
      ) {
        // get token expiredate
        const tokenExpireAtString = localStorage.getItem("tokenExpireAt");
        const currentTime = new Date().getTime();

        // check not null or empty string
        if (tokenExpireAtString !== null && !tokenExpireAtString) {
          return; // return if no token.
        }

        // convert tokenString to Date
        const tokenExpireAt = new Date(tokenExpireAtString);

        // check if token expired
        if (tokenExpireAt && currentTime > tokenExpireAt.getTime()) {
          // get refreshtoken
          const refreshTokenExpireAtString = localStorage.getItem(
            "refreshTokenExpireAt"
          );

          // IF no refreshtoken exist
          if (!refreshTokenExpireAtString) {
            localStorage.removeItem("tokenExpireAt");
            localStorage.removeItem("refreshTokenExpireAt");
            localStorage.removeItem("phoneNumber");
            return;
          }

          const refreshTokenExpireAt = new Date(refreshTokenExpireAtString);

          // is refreshtokenExpired?
          if (currentTime > refreshTokenExpireAt.getTime()) {
            localStorage.removeItem("tokenExpireAt");
            localStorage.removeItem("refreshTokenExpireAt");
            localStorage.removeItem("phoneNumber");
            return; // quit if refresh token expired
          } else {
            // refresh token
            await loginRefreshV2();
          }
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default protectedClient;

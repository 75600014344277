import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import OrderList from "../../../../components/order/orderList";
import { getOrders } from "../../../../utils/api/restClient";
import { translatePage } from "../../../../utils/services/translate";
import {
  setLoading,
  setShowNavigationMenu,
  setOrderList,
} from "../../../../redux/actions";
import "./ordersPage.scss";

function OrdersPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setShowNavigationMenu(false));
    dispatch(setLoading(true));
    getOrders().then((res) => {
      if (res === undefined || res.statusCode === 400) {
        dispatch(setLoading(false));
      }
      if (res.orders !== null) {
        const sortedArray = res.orders.sort(
          (a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime)
        );
        dispatch(setOrderList(sortedArray));
      }
      dispatch(setLoading(false));
    });
  }, [dispatch]);

  return (
    <>
      <div className="d-flex justify-content-center min-vh-100 pt-5 mt-4 ">
        <div className="OrderListContainer">
          <div className="centered-container">
            <div className="row mb-2">
              <div className="col-12">
                <Link
                  className="text-dark pe-2 text-decoration-none text-l"
                  to="/"
                >
                  Start
                </Link>
                <span className="pe-2 text-lightgreen text-m">/</span>
                <span className="text-muted text-l">
                  {translatePage(
                    translations,
                    language,
                    "OrdersPage",
                    "myPurchases"
                  )}
                </span>
              </div>
            </div>
          </div>
          <h1 className="p-4 bg-light text-center ">
            {translatePage(translations, language, "OrdersPage", "title")}
          </h1>
          <OrderList />
          <div className="OrderListContainer border-top pt-3">
            <p className="text-center">
              {translatePage(
                translations,
                language,
                "OrdersPage",
                "lookingForTicketsMessage"
              )}
            </p>
            <button
              className="btn btn-success w-100"
              onClick={() => navigate("/home/tickets")}
            >
              {translatePage(
                translations,
                language,
                "OrdersPage",
                "myTicketsButtonText"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrdersPage;

// @flow
import { ProjectActionTypes } from "./constants";

const INIT_STATE = {
  project_list: null,
  my_project_list: null,
  project_list_filtered_by_category: null,
  project_slug: null,
  project: null,
};

type ProjectAction = { type: string, payload?: string | null };

type State = {};

const Product = (state: State = INIT_STATE, action: ProjectAction): any => {
  switch (action.type) {
    case ProjectActionTypes.SET_PROJECT_LIST:
      return {
        ...state,
        project_list: action.payload,
      };
    case ProjectActionTypes.SET_SELECTED_PROJECT_SLUG:
      return {
        ...state,
        project_slug: action.payload,
      };
    case ProjectActionTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case ProjectActionTypes.SET_MY_PROJECT_LIST: {
      return {
        ...state,
        my_project_list: action.payload,
      };
    }
    case ProjectActionTypes.SET_SELECTED_PROJECTS_BY_CATEGORY:
      if (action.payload == null) {
        return {
          ...state,
          project_list_filtered_by_category: null,
        };
      } else {
        if (state.project_list == null) {
          return {
            ...state,
            project_list_filtered_by_category: null,
          };
        }
        const filteredProjects = state.project_list.filter((project) =>
          project.categories.some(
            (category) => category.name === action.payload
          )
        );

        return {
          ...state,
          project_list_filtered_by_category:
            filteredProjects.length > 0 ? filteredProjects : null,
        };
      }
    default:
      return state;
  }
};

export default Product;

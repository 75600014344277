import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProduct, setBasketData } from "../../redux/actions";
import ProductDescription from "./productDescription";
import "./styles.scss";
import MySvg from "../../assets/icons/buttons/Btn_info.svg";
import { translateComponent } from "../../utils/services/translate";
import { getCurrencyLabel } from "../../utils/helper/currencyLabel";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-gb";
import "moment/locale/fi";

const Products = () => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.Order.basket);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const project = useSelector((state) => state.Project.project);
  const product_list = useSelector((state) => state.Product.product_list);
  const selected_product = useSelector(
    (state) => state.Product.selected_product
  );

  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const initialProductQuantities = {};
  product_list.forEach((product) => {
    const productInBasket = basket.products.find(
      (item) => item.id === product.id
    );
    initialProductQuantities[product.id] = productInBasket
      ? productInBasket.quantity
      : 0;
  });

  const initialSelectedDepartures = {};
  basket.products.forEach((product) => {
    if (product.departure) {
      initialSelectedDepartures[product.id] = product.departure;
    }
  });

  const initialDropdownOpen = {};
  product_list.forEach((product) => {
    if (product.type === "reservedSeatTicket") {
      initialDropdownOpen[product.id] = true;
    }
  });

  const [productQuantities, setProductQuantities] = useState(
    initialProductQuantities
  );
  const [selectedDepartures, setSelectedDepartures] = useState(
    initialSelectedDepartures
  );
  const [dropdownOpen, setDropdownOpen] = useState(initialDropdownOpen);
  const [expandedProducts, setExpandedProducts] = useState({});

  const toggleDropdown = (productId) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const toggleDropdownResetState = (productId) => {
    productHandler(productId, "reset");
    selectDeparture(productId, null);
    setDropdownOpen((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const selectDeparture = (productId, departure) => {
    setSelectedDepartures((prev) => ({ ...prev, [productId]: departure }));
    setDropdownOpen((prev) => ({ ...prev, [productId]: false }));
    updateBasket(productQuantities, {
      ...selectedDepartures,
      [productId]: departure,
    });
  };

  const productHandler = (productId, action) => {
    setProductQuantities((prevQuantities) => {
      const currentValue = prevQuantities[productId];
      let newValue = currentValue;

      if (action === "up" && currentValue < 9) {
        newValue = currentValue + 1;
      } else if (action === "down" && currentValue > 0) {
        newValue = currentValue - 1;
      } else if (action === "reset" || newValue === 0) {
        newValue = 0;
        setExpandedProducts((prev) => {
          const updated = { ...prev };
          delete updated[productId];
          return updated;
        });
      }

      const updatedQuantities = { ...prevQuantities, [productId]: newValue };
      updateBasket(updatedQuantities, selectedDepartures);
      return updatedQuantities;
    });
  };

  const updateBasket = (updatedQuantities, updatedDepartures) => {
    const tempBasket = {
      basketValid: false,
      phoneValid: basket.phoneValid,
      phone: basket.phone,
      total: 0,
      products: [],
    };

    product_list.forEach((product) => {
      const quantity = updatedQuantities[product.id];
      if (quantity > 0) {
        const departure = updatedDepartures[product.id];
        const updatedProduct = { ...product, quantity, departure };
        tempBasket.products.push(updatedProduct);
        tempBasket.basketValid = true;
      }
    });

    const total = tempBasket.products.reduce(
      (acc, product) => acc + product.quantity * product.price,
      0
    );
    tempBasket.total = total;
    dispatch(setBasketData(tempBasket));
  };

  const formatDateWithDay = (date, locale) => {
    const formattedDate = moment
      .utc(date, moment.ISO_8601)
      .locale(locale)
      .format("dddd, YYYY-MM-DD HH:mm");
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  const toggleProduct = (productId) => {
    setExpandedProducts((prev) => {
      const isExpanded = !!prev[productId];
      const updated = { ...prev };
      if (isExpanded) {
        delete updated[productId];
      } else {
        updated[productId] = true;
      }
      return updated;
    });
  };

  if (!product_list) {
    return <p className="text-center">Loading...</p>;
  }
  return (
    <div className="row">
      <div className="col-12">
        {product_list.map((product) => {
          const translation = product.translations.find(
            (t) => t.locale === language
          );
          if (!translation) return null;

          const isDepartureSelected =
            product.type !== "reservedSeatTicket" ||
            !!selectedDepartures[product.id];
          const buttonStyle = isDepartureSelected
            ? {}
            : { opacity: 0.2, pointerEvents: "none" };

          return (
            <div key={product.id} className="product-container">
              <div className="details-actions-wrapper">
                <div className="product-details w-100 m-0">
                  {expandedProducts[product.id] ? null : (
                    <button
                      className="p-0 pt-1 clean-button m-0 w-100 text-start"
                      onClick={() => toggleProduct(product.id)}
                    >
                      <span className="text-extra-bold">{product.name}</span>
                      <span className="chevron-btn-right float-end p-0 m-0 mt-4 me-2"></span>
                      <span className="float-end pe-3 mt-3">
                        {product.price}{" "}
                        {getCurrencyLabel(language, project.currency)}
                      </span>
                      <span className="p-0 m-0 text-m d-block">
                        ({translation.description})&nbsp;&nbsp;&nbsp;
                      </span>
                    </button>
                  )}
                  {expandedProducts[product.id] && (
                    <div className="product-card rounded">
                      <div
                        className="d-block mt-3"
                        onClick={() => toggleDropdownResetState(product.id)}
                      >
                        <span className="text-extra-bold mb-0 d-inline">
                          {translation.name}
                        </span>
                        <span className="chevron-btn-down float-end p-0 m-0 me-2 mt-1"></span>
                      </div>
                      <span
                        className="text-m"
                        onClick={() =>
                          dispatch(
                            setSelectedProduct(
                              selected_product === product ? null : product
                            )
                          )
                        }
                      >
                        {translation.description === "" ? (
                          <span className="span-link p-0 m-0 text-m pe-2">
                            {translateComponent(
                              translations,
                              language,
                              "products",
                              "showInformation"
                            )}
                          </span>
                        ) : (
                          <>
                            <span className="p-0 m-0 text-m d-block">
                              ({translation.description})&nbsp;&nbsp;&nbsp;
                            </span>
                            <span className="span-link p-0 m-0 text-m pe-2">
                              {translateComponent(
                                translations,
                                language,
                                "products",
                                "showInformation"
                              )}
                            </span>
                          </>
                        )}
                        <img
                          height="18"
                          width="20"
                          src={MySvg}
                          alt="information"
                        />
                      </span>
                      {selectedDepartures[product.id] ? (
                        product.type === "reservedSeatTicket" && (
                          <div className="product-actions-container ps-5 pb-5">
                            <div className="product-actions m-0">
                              <span
                                className="btn-remove me-1"
                                onClick={() =>
                                  productHandler(product.id, "down")
                                }
                              ></span>
                              <input
                                type="text"
                                id={"productId-" + product.id}
                                className="product-count text-center"
                                value={productQuantities[product.id]}
                                readOnly
                              />
                              <span
                                className="btn-add ms-1"
                                onClick={() => productHandler(product.id, "up")}
                              ></span>
                            </div>
                            <div>
                              <p className="product-price">
                                {product.price}{" "}
                                {getCurrencyLabel(language, project.currency)}
                              </p>
                            </div>
                          </div>
                        )
                      ) : (
                        <span className="text-end ms-5 ps-4">
                          {product.price}{" "}
                          {getCurrencyLabel(language, project.currency)}
                        </span>
                      )}

                      {product.type === "ticket" && (
                        <div className="product-actions-container ps-5 pb-5 mb-4 pt-3">
                          <div className="product-actions m-0">
                            <span
                              className="btn-remove me-1"
                              onClick={() => productHandler(product.id, "down")}
                            ></span>
                            <input
                              type="text"
                              id={"productId-" + product.id}
                              className="product-count text-center"
                              value={productQuantities[product.id]}
                              readOnly
                            />
                            <span
                              className="btn-add ms-1"
                              onClick={() => productHandler(product.id, "up")}
                            ></span>
                          </div>
                          <div>
                            <p className="product-price">
                              {product.price}{" "}
                              {getCurrencyLabel(language, project.currency)}
                            </p>
                          </div>
                        </div>
                      )}

                      {product.type === "reservedSeatTicket" && (
                        <div className="departure-container">
                          {dropdownOpen[product.id] && (
                            <>
                              <div className="table-container">
                                <table className="w-100">
                                  <thead className="table-header">
                                    <tr>
                                      <th className="table-cell text-white">
                                        {translateComponent(
                                          translations,
                                          language,
                                          "products",
                                          "selectDepatureTime"
                                        )}
                                      </th>
                                      <th className="table-cell text-start"></th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-body">
                                    {product.Departures.map((departure) => {
                                      const departureDate = moment
                                        .utc(
                                          departure.departureTime,
                                          moment.ISO_8601
                                        )
                                        .toDate();
                                      if (
                                        departure.maxSeats ===
                                        departure.reservedSeats
                                      ) {
                                        return (
                                          <tr
                                            key={departure.id}
                                            className="table-row"
                                          >
                                            <td className="table-cell text-danger">
                                              <p className="text-m p-0 m-0 text-extra-bold">
                                                {moment
                                                  .utc(departureDate)
                                                  .format("YYYY-MM-DD HH:mm")}
                                              </p>
                                            </td>
                                            <td className="table-cell bg-grey">
                                              <span className="btn-link p-0 mt-4 m-0 text-danger text-m">
                                                {translateComponent(
                                                  translations,
                                                  language,
                                                  "products",
                                                  "outOfCapacity"
                                                )}
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      } else {
                                        return (
                                          <tr
                                            key={departure.id}
                                            className="table-row"
                                            onClick={() =>
                                              selectDeparture(
                                                product.id,
                                                departure
                                              )
                                            }
                                          >
                                            <td className="table-cell">
                                              <p className="text-m p-0 m-0 text-extra-bold">
                                                {moment
                                                  .utc(departureDate)
                                                  .format("YYYY-MM-DD HH:mm")}
                                              </p>
                                            </td>
                                            <td className="table-cell">
                                              <span className="text-m btn-link p-0 mt-4 m-0">
                                                {translateComponent(
                                                  translations,
                                                  language,
                                                  "products",
                                                  "selectDeparture"
                                                )}
                                              </span>
                                              <span className="chevron-btn-right float-end p-0 m-0 mt-1"></span>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}

                          {selectedDepartures[product.id] && (
                            <>
                              <div className="mt-5 pt-3 text-center">
                                <span className="text-dark text-m text-extra-bold pt-5 text-center">
                                  {translateComponent(
                                    translations,
                                    language,
                                    "products",
                                    "departureTime"
                                  )}
                                  :{" "}
                                </span>

                                <span className="text-m ps-1 me-4">
                                  (
                                  {formatDateWithDay(
                                    selectedDepartures[product.id]
                                      .departureTime,
                                    language
                                  )}
                                  )
                                </span>
                              </div>

                              <button
                                className="btn btn-secondary btn-secondary-departure text-m p-1 pt-2 ps-4 m-0 mt-2 mb-2 me-2 w-100"
                                onClick={() => toggleDropdown(product.id)}
                              >
                                <span className="m-0 p-0 text-m text-center">
                                  {translateComponent(
                                    translations,
                                    language,
                                    "products",
                                    "changeDepartureTime"
                                  )}
                                </span>
                                <span className="btn-chevron-down me-2"></span>
                              </button>
                            </>
                          )}
                          {basket.basketValid ? (
                            <div>
                              <button
                                className="btn btn-success text-m p-2 pt-2 ps-4 m-0 mt-2 mb-2 me-2 w-100"
                                onClick={scrollToBottom}
                              >
                                {translateComponent(
                                  translations,
                                  language,
                                  "products",
                                  "proceedToPayment"
                                )}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div className="product-description-container mt-3 ">
                        {selected_product != null &&
                        selected_product.id === product.id ? (
                          <ProductDescription
                            product={selected_product}
                            language={language}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div ref={bottomRef}></div>
      </div>
    </div>
  );
};

export default Products;

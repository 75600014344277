// @flow
import { PaymentActionTypes } from "./constants";

const INIT_STATE = {
  selected_payment_option: "swish",
  payment_error: false,
  payment_result_error: false,
  swish_response_status: null,
};

type PaymentAction = { type: string, payload?: string | null };

type State = {};

const Payment = (state: State = INIT_STATE, action: PaymentAction): any => {
  switch (action.type) {
    case PaymentActionTypes.SET_PAYMENT_ERROR:
      return {
        ...state,
        payment_error: action.payload,
      };
    case PaymentActionTypes.SET_PAYMENT_ERROR_RESULT:
      return {
        ...state,
        payment_result_error: action.payload,
      };
    case PaymentActionTypes.SET_SWISH_PAYMENT_RESPONSE_STATUS:
      return {
        ...state,
        swish_response_status: action.payload,
      };
    case PaymentActionTypes.SET_SELECTED_PAYMENT_OPTION:
      if (action.payload === "visa" && action.payload !== "mastercard") {
        return {
          ...state,
          selected_payment_option: "card",
        };
      } else {
        return {
          ...state,
          selected_payment_option: action.payload,
        };
      }

    default:
      return state;
  }
};

export default Payment;
